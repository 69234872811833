/* chat css start */

.chat_box_section {
  border: 3px solid #e1e1e1;
  border-radius: 5px;
}

.user_search_box {
  background: #fff;
  padding: 15px 15px;
}

.chatuser_list {
  background: #fff;
  overflow: hidden;
  height: calc(100vh - 219px);
  height: -moz-calc(100vh - 219px);
  height: -webkit-calc(100vh - 225px);
  overflow-x: hidden;
  overflow-y: auto;
  /* padding: 0px 5px 2px 13px; */
}

.chat_users_box {
  padding: 0;
  border-right: 1px solid #e1e1e1;
}

.user_box .user_box_body h4 .badge-secondary {
  float: right;
}

.user_box {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 8px 10px;
  border-bottom: 1px solid #d9d9d9;
  /* cursor: pointer; */
  background: #ffffff;
}

.user_box img {
  max-width: 40px;
  min-width: 40px;
  max-height: 40px;
  min-height: 40px;
  border-radius: 50px;
  /* border: 2px solid white; */
  cursor: pointer;
}

.user_box .user_box_body {
  padding: 3px 10px;
  line-height: 15px;
  width: 100%;
  cursor: pointer;
}

.user_box .user_box_body h4 {
  font-size: 15px;
  width: 100%;
  display: block;
  margin-bottom: 2px;
}

.user_box .user_box_body h4 .badge-secondary {
  background: #6c757d;
}

.user_box .user_box_body small {
  font-size: 12px;
}

.user_box .user_box_body small i {
  font-size: 8px;
  position: relative;
  top: -2px;
}

.user_box.active {
  background: #dae0ee;
}

/* .user_box :hover {
  background-color: #dae0ee;
} */

.user_box.online .user_box_body small i {
  color: #15a620;
}

.user_box.away .user_box_body small i {
  color: #ffa400;
}

.chat_content_box {
  background: #e6e6e6;
  padding: 0;
}

.chat_content_box .user_box {
  background: #fff;
  padding: 5px 15px;
}

.chat_content_box .user_box .user_box_body {
  padding: 8px 10px;
  line-height: 15px;
}

.chat_content_box .user_box .user_box_body h4 {
  font-size: 18px;
  margin-bottom: 0px;
}

.chat_content_box img {
  max-width: 50px;
  min-width: 50px;
  max-height: 50px;
  min-height: 50px;
  border-radius: 50px;
  /* border: 2px solid white; */
}

.chat_content_box .userchat_body {
  background: #fff;
  /* overflow: hidden; */
  height: calc(100vh - 250px);
  height: -moz-calc(100vh - 250px);
  height: -webkit-calc(100vh - 255px);
  overflow-x: hidden;
  overflow-y: auto;
  padding: 15px 15px;
}

.user_submit_chat {
  background: #fff;
  padding: 10px 15px;
  border-top: 1px solid #d9d9d9;
}

.chat_message {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding: 0px 0px 30px 0px;
  clear: both;
  margin: 0px 10px 0px 0px;
  font-size: 15px;
}

.chat_message img {
  max-width: 40px;
  min-width: 40px;
  max-height: 40px;
  min-height: 40px;
  border-radius: 50%;
  /* border: 2px solid #e7e7e7; */
}

.chat_message p {
  padding: 8px 20px;
  border-radius: 30px;
  position: relative;
  font-size: 15px !important;
  line-height: 23px !important;
}

.chat_message.chat_right {
  float: right;
}

.chat_message.chat_right p {
  margin: 0px 10px 0px 50px;
  width: 100%;
  text-align: left;
  background: #d8e2fa;
}

.chat_message.chat_right p time {
  position: absolute;
  right: 15px;
  bottom: -18px;
  width: 100%;
  font-size: 11px;
  color: #918d8d;
  text-align: right;
  font-weight: normal;
}

.chat_message.chat_right p::before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-left: 17px solid #d8e2fa;
  position: absolute;
  top: 5px;
  right: -7px;
}

.chat_message.chat_left {
  float: left;
}

.chat_message.chat_left p {
  margin: 0px 50px 0px 10px;
  width: 100%;
  text-align: left;
  background: #f5eaff;
}

.chat_message.chat_left p time {
  position: absolute;
  left: 15px;
  bottom: -18px;
  width: 100%;
  font-size: 11px;
  color: #898787;
  text-align: left;
  font-weight: normal;
}

.chat_message.chat_left p::before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  border-top: 15px solid transparent;
  border-bottom: 15px solid transparent;
  border-right: 17px solid #f5eaff;
  position: absolute;
  top: 5px;
  left: -7px;
}

.chat_old_messsage_date {
  display: inline-block;
  width: 100%;
  text-align: center;
  /* margin: 20px 0px; */
  border-top: 1px solid #d9d9d9;
}

.chat_old_messsage_date span {
  position: relative;
  top: -16px;
  background: #fff;
  padding: 0px 10px;
  color: #918d8d;
  font-size: 12px;
}

.user_submit_chat .input-group-append {
  width: 45px;
  border: 1px solid #bb6bd9;
  text-align: center;
  line-height: 40px;
  border-radius: 50px !important;
  height: 45px;
  padding: 0px;
  font-size: 23px;
  background: #bb6bd9;
  color: #fff;
  margin-left: 10px !important;
}

.userprofile_box {
  position: relative;
  width: 100px;
  margin: 0 auto;
}

.userprofile_box img {
  min-width: 100px;
  border-radius: 50px;
  max-width: 100px;
  max-height: 100px;
  min-height: 100px;
}

.userprofile_box i {
  position: absolute;
  right: 0px;
  bottom: 13px;
  color: #48a20f;
  border: 2px solid white;
  border-radius: 50px;
}

.input-group-append,
.input-group-prepend {
  cursor: pointer;
}

.user_submit_chat .form-control {
  height: 50px;
  border: 1px solid #dddddd;
  padding: 0px 20px;
  border-radius: 50px !important;
}

.user_search_box .input-group-append .input-group-text {
  padding: 10px 10px;
  border-radius: 0px 5px 5px 0px;
}

.user_submit_chat .input-group-text {
  background: #Fff;
  border-radius: 50px;
  padding: 0px 20px;
  font-size: 20px;
  border: #fff;
}

.chat_front_user_box {
  background: #f4f4f4;
  padding: 15px 15px;
}

.chat_front_user_box .chat_front_user_main {
  position: absolute;
  width: 100%;
  left: 0;
  top: 50%;
  -webkit-transform: translate(0%, -50%);
  transform: translate(0%, -50%);
  -ms-transform: translate(0%, -50%);
}

.chat_front_user_box .chat_front_user_footer {
  margin-top: 35px;
}

.chat_front_user_box .chat_front_user_footer p {
  margin: 5px 0px;
}

.chat_front_user_box .chat_front_user_footer p:nth-child(1) {
  font-style: italic;
  font-weight: 600;
}

.chat_front_user_box .learn_more {
  text-decoration: underline;
}

.mobile_back {
  background: #dbdbdb;
}

.mobile_back .chatback_btn {
  font-size: 26px;
  padding: 0px 15px;
  color: #2a2a2a;
}

.emoji_box {
  background: #fff;
  position: absolute;
  z-index: 9;
  padding: 10px 15px;
  border-radius: 10px;
  bottom: -13px;
  display: inline-block;
}

.emoji_box i {
  cursor: pointer;
  font-size: 16px;
}

.color_div {
  width: 10px;
  margin-right: 4px;
}

.user_box .careteam {
  background-color: #F2994A;
}

.user_box .fandsfam {
  background-color: #F2C94C;
}

.user_box .admin {
  background-color: #56CCF2;
}

.user_box .group {
  background-color: #56CCF2;
}

.forImagecareteam {
  filter: brightness(0) saturate(100%) invert(83%) sepia(45%) saturate(2133%) hue-rotate(320deg) brightness(93%) contrast(105%);
}

.forImagefandsfam {
  filter: brightness(0) saturate(100%) invert(95%) sepia(95%) saturate(7288%) hue-rotate(320deg) brightness(103%) contrast(90%);
}

.forImageadmin {
  filter: invert(3%) sepia(80%) saturate(448%) hue-rotate(167deg) brightness(100%) contrast(90%);
}

.timeAgo_span {
  float: right;
  font-size: 12px;
  color: var(--gray-3, #828282);
}

/* chat css end */
/*=======================================================
  //////////////RESPONSIVE MEDIA QUERY START///////////////
  =======================================================*/
@media (max-width: 767px) {
  .mobile_back {
    display: block !important;
  }

  .chat_content_box {
    display: none;
  }

  .userchat_body {
    height: calc(100vh - 203px) !important;
  }

  .chat_message p {
    padding: 8px 15px;
    border-radius: 20px;
  }

  .chat_message.chat_left p {
    margin: 0px 0px 0px 10px;
  }

  .chat_message.chat_right p {
    margin: 0px 10px 0px 0px;
  }

  .chat_message {
    font-size: 12px;
  }
}

/* Style for the second list item */
.careTeamTab {
  align-items: center !important;
  display: flex !important;
  margin-right: 10px;
  font-size: 14px;
}

.careTeamTab:before {
  content: "•";
  color: #F2994A;
  ;
  margin-right: 2px;
  font-size: 3.2em;
  line-height: 0.8em;
}

.FfTab:before {
  content: "•";
  color: #F2C94C;
  margin-right: 2px;
  font-size: 3.2em;
  line-height: 0.8em;
}

.adminTab:before {
  content: "•";
  color: #56CCF2;
  margin-right: 2px;
  font-size: 3.2em;
  line-height: 0.8em;
}

/*=======================================================
  //////////////RESPONSIVE MEDIA QUERY END///////////////
  =======================================================*/
.chat_menu {
  opacity: 0;
}

.moreIcon {
  cursor: pointer;
}

.userImgDiv {
  position: relative;
  height: 42px;
}

.count_badge {
  background-color: #15a620;
  height: 15px;
  width: 15px;
  font-size: 10px;
  padding: 3px 5px;
}

.online_badge {
  z-index: 1;
  background-color: #15a620;
  color: #15a620;
  height: 12px;
  width: 12px;
  font-size: 10px;
  padding: 3px 5px;
  position: absolute;
  right: 0px;
  bottom: 2px;
  border-radius: 50px;
}