.themeColor {
  color: #bb6bd9;
}

.subscriptionBox .progress-bar {
  background: #9b51e0;
}

.promotionalC ul,
.profileB ul {
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 14px;
}

.mt-3.subscriptionBox p {
  font-size: 14px;
}

.profileB ul li {
  display: flex;
  justify-content: space-between;
  line-height: 28px;
}

.profileB ul li p {
  margin: 0;
  display: flex;
}

.profileB ul li p span {
  margin-right: 4px;
}

.profileB ul li p a {
  color: #2d9cdb;
}

.profileB .btn {
  color: #9b51e0;
  border: 1px solid #9b51e0;
  padding: 4px 10px;
  margin-top: 20px;
}

.promotionalCode input {
  width: 100px;
  border: 1px solid #bb6bd9;
  border-radius: 5px;
  margin-left: 20px;
}

.updateBtn {
  background: #9b51e0;
  color: #fff !important;
  padding: 5px 15px;
  margin-top: 15px;
}

.planSelect {
  text-align: center;
  border: 1px solid #9b51e0;
  margin: 0;
  padding: 15px 10px;
  align-items: center;
  border-radius: 5px;
  margin-top: 10px;
}

.planSelect h5 {
  color: #9b51e0;
  margin: 0;
}

.planSelect input[type="radio"] {
  width: 25px;
  height: 25px;
}

.subscriptionPlan .onoff .slider {
  width: 100%;
  height: 25px;
}

.subscriptionPlan .onoff {
  margin: 0px 8px;
}

.subscriptionPlan {
  margin-bottom: 30px;
}

.subscriptionPlan .onoff input:checked+.slider {
  background: #c4c4c4;
}

.billing_back {
  color: #0d6efd;
  cursor: pointer;
  z-index: 1000;
  font-size: 13px;
  margin-top: 3px;
}

.popupCloseIcon {
  background: none;
  border: 0;
  font-size: 25px;
}

.edit_clogo {
  position: absolute;
  left: 62px;
  top: 0px;
  cursor: pointer;
  font-size: 22px;
}

.edit_clogo input {
  width: 20px;
  height: 30px;
  font-size: 0;
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.billingHistory {
  height: 65vh;
  overflow: auto;
  padding: 0 24px !important;
}