.blog_img img {
    width: 60px;
    height: 42px;
    object-fit: cover;
    border-radius: 4px;
}
.view_icon {
    border: 1px solid #c497fc;
    display: inline-block;
    width: 23px;
    height: 23px;
    line-height: 21px;
    text-align: center;
    border-radius: 3px;
}



.switch {
	position: relative;
	vertical-align: middle;
	width: 100px;
	height: 27px;
	background:#fff;
	cursor: pointer;
	box-sizing:content-box;
}
.switch-input {
	position: absolute;
	top: 0;
	left: 0;
	opacity: 0;
	box-sizing:content-box;	
}
.switch-label {
	position: relative;
	display: block;
	height: inherit;
	font-size: 12px;
	background: #eceeef;
	box-sizing:content-box;
	border: 1px solid #e4dcdc;
    overflow: hidden;
	border-radius: 5px;
}
.switch-label:before, .switch-label:after {
	position: absolute;
	top: 50%;
	margin-top: -.5em;
	line-height: 1;
	-webkit-transition: inherit;
	-moz-transition: inherit;
	-o-transition: inherit;
	transition: inherit;
	box-sizing:content-box;
}
.switch-label:before {
	content: attr(data-off);
	right: 11px;
	color: #aaaaaa;
	text-shadow: 0 1px rgba(255, 255, 255, 0.5);
}
.switch-label:after {
	content: attr(data-on);
	left: 11px;
	color: #FFFFFF;
	text-shadow: 0 1px rgba(0, 0, 0, 0.2);
	opacity: 0;
}
.switch-input:checked ~ .switch-label {
	background: #2cd07e;
}
.switch-input:checked ~ .switch-label:before {
	opacity: 0;
}
.switch-input:checked ~ .switch-label:after {
	opacity: 1;
}
.switch-handle {
    position: absolute;
    top: 3px;
    left: 3px;
    width: 23px;
    height: 23px;
    background: #d8d8d8;
}
.switch-handle:before {
	content: "";
	position: absolute;
	top: 50%;
	left: 50%;
	margin: -6px 0 0 -6px;
	width: 12px;
	height: 12px;
	background: #d8d8d8;
	border-radius: 6px;
}
.switch-input:checked ~ .switch-handle {
	left: 74px;
	background:#fff;
}
.switch-input:checked ~ .switch-handle:before {
	background:#fff;
}
 
/* Transition
========================== */
.switch-label, .switch-handle {
	transition: All 0.3s ease;
	-webkit-transition: All 0.3s ease;
	-moz-transition: All 0.3s ease;
	-o-transition: All 0.3s ease;
}
