.invoiceBody {
  margin: 0 auto;
}

.invoiceBody :global(.form-control) {
  margin-bottom: 15px;
}

.invoiceBody .right ul,
.invoiceBody .left ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.invoiceBody .right ul li,
.invoiceBody .left ul li {
  display: flex;
  justify-content: space-between;
}

.invoiceBody h5 {
  text-align: center;
  font-size: 28px;
  margin-bottom: 40px;
}

.invoice_header {
  margin-left: 20px;
  color: var(--main);
}

.btnHead {
  /* background-image: linear-gradient(to right, var(--main), var(--main2)); */
  background-color: var(--main);
  color: #fff;
  border: none;
  border-radius: 10px;
  min-width: 100px;
  line-height: 25px;
  font-size: 15px;
}

.btnHead:hover {
  background-color: var(--main);
}

.invoiceBack {
  margin-right: 20px;
  font-size: 14px;
}

.fweight {
  font-weight: 400;
}

.invoice_desc_border {
  border-top: none;
  border-left: none;
  border-right: none;
}

.lable {
  top: 12px;
  position: relative;
  background-color: white;
  margin-left: 10px;
}

