.btns_head .btn {
  background-image: linear-gradient(to right, var(--main), var(--main2));
  color: #fff;
  border: none;
  margin-left: 10px;
  padding: 8px 15px;
  border-radius: 50px;
  min-width: 100px;
  line-height: 25px;
  font-size: 15px;
}

.careSearch {
  position: absolute !important;
  top: 5px;
  left: 50%;
  transform: translate(-66%, -18%);
  z-index: 1;
  width: 27.12%;
}

.buttonAddSet button {
  /* position: absolute; */
  right: 10px;
  transform: translate(100%, 0%);
}
.buttonAddSet .position-relative {
  padding-right: 22px !important;
}

.btn_table.btn-success {
  height: 26px;
  line-height: 1px;
  font-size: 12px;
}

.profile_img_table {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 50px;
}

.profle_img_box stong {
  margin-left: 5px;
}

.cts_datatable {
  font-family: "Poppins", sans-serif !important;
}

.table.popupTbleList>thead tr th {
  color: #222;
}

.table_arrow {
  font-size: 34px;
  position: absolute;
  right: 10px;
  color: #616161;
  top: -3px;
  padding: 0px 10px;
}

.ag-theme-alpine .ag-cell,
.ag-theme-alpine .ag-full-width-row .ag-cell-wrapper.ag-row-group {
  line-height: 53px !important;
}

.ag-theme-alpine [class^="ag-"],
.ag-theme-alpine [class^="ag-"]:focus,
.ag-theme-alpine [class^="ag-"]:after,
.ag-theme-alpine [class^="ag-"]:before {
  justify-content: center !important;
}

.ag-theme-alpine .ag-ltr .ag-cell {
  text-align: center !important;
}

.shiftsHistory {
  display: flex;
  justify-content: space-between;
  margin: 20px 0px 5px 0px;
}

.shiftsHistory ul button {
  background: transparent;
  border: none;
  font-size: 14px;
}

.shiftsHistory ul button:focus,
.shiftsHistory ul button.active {
  color: var(--main);
}

.shiftsHistory h5 {
  margin: 0;
  font-size: 18px;
}

.ract_tab_list li .nav-link {
  background: transparent;
  color: #6b6b6b;
  border: none;
  border-bottom: 2px solid #b3b3b3;
  padding: 5px 15px;
  font-size: 16px;
  font-weight: 400 !important;
}

.ract_tab_list li .nav-link.active,
.ract_tab_list li .nav-link:hover,
.ract_tab_list li .nav-link:focus {
  background: transparent;
  border: none;
  color: var(--main);
  border-bottom: 2px solid var(--main);
  padding-bottom: 0px !important;
}

/* .table_half tr td {
  width: 50%;
} */

.table tr:nth-child(odd) td {
  background: #fafafa;
  box-shadow: none;
}

.table tr td {
  border: none;
  font-size: 14px;
  padding: 10px 15px;
}

.table .rowHead td {
  width: 100% !important;
  font-weight: 400;
  color: var(--main) !important;
  background: #fff !important;
}

.tb_title {
  font-size: 16px;
  margin-bottom: 15px;
  margin-top: 25px;
  color: var(--main);
  text-align: left;
}

.textfont p {
  font-size: 14px;
  font-weight: 300;
  color: black;
  margin-bottom: 6px;
}

.addPlus .dropdown-toggle {
  font-size: 24px;
  color: #bdbdbd;
  width: 38px;
  border: 1px solid #bbb2b2;
  text-align: center;
  height: 38px;
  line-height: 35px;
  cursor: pointer;
  border-radius: 8px;
  padding: 0px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
}

.addPlus .dropdown-toggle svg {
  font-size: 20px;
}

.addPlus .dropdown-toggle:focus {
  color: #bdbdbd !important;
  background: #fff !important;
  box-shadow: none !important;
  border: 1px solid #bbb2b2 !important;
}

.addPlus .dropdown-menu {
  min-width: 450px;
  padding: 15px 15px;
}

.addPlus .dropdown-toggle {
  color: #bdbdbd !important;
  border: 1px solid #bbb2b2 !important;
  background: #fff !important;
}

.addPlus .dropdown-toggle::after {
  display: none;
}

.addPlus .dropdown-menu .actionBtns {
  display: flex;
  justify-content: end;
}

.addPlus .dropdown-menu .living_box:focus {
  border: 1px solid #9b51e0;
}

.addPlus .dropdown-menu .living_box {
  margin: 0;
}

.addPlus .dropdown-menu .living_box h5 {
  font-size: 13px;
  min-height: auto;
}

.addPlus .dropdown-menu .createTemplate .btn {
  margin: 0;
  width: auto;
  margin-left: 10px;
}

.addPlus .dropdown-menu .actionBtns .btn {
  padding: 5px 12px;
  font-size: 13px;
  background: #9b51e0;
  color: #fff;
  border-radius: 50px;
  margin-left: 10px;
}

.addPlus .dropdown-menu .createTemplate .btn {
  margin: 0;
  width: auto;
  height: auto;
  line-height: 21px;
  border: 1px solid #9b51e0;
  padding: 5px 12px;
  font-size: 13px;
  background: #9b51e0;
  color: #fff;
  border-radius: 50px;
  margin-left: 10px;
}

.addPlus .dropdown-menu .createTemplate .btn:focus {
  border: 1px solid #9b51e0 !important;
  background: #9b51e0 !important;
  color: #fff !important;
}

.ract_tab_data {
  padding-top: 30px;
}

.seprate_line {
  border-right: 1px solid var(--main);
}

.table> :not(:first-child) {
  border-top: 1px solid #dddddd;
}

.table>thead tr th {
  font-size: 14px;
  font-weight: 500;
  border: none;
  padding: 10px 15px;
  color: var(--main);
}

.top_menubar .nav-tabs {
  float: left;
}

.top_menubar {
  display: inline-block;
  width: 100%;
}

.care_plan_list .accordion-item .accordion-header .accordion-button {
  padding: 15px 2px;
  border-radius: 0px;
}

.care_plan_list .accordion-item {
  border: none;
  border-radius: 0px;
  border-bottom: 1px solid #dedede;
}

.care_plan_list .accordion-item .accordion-header .accordion-button img {
  margin-right: 10px;
}

.care_plan_list .accordion-item .accordion-header .accordion-button:not(.collapsed) {
  background: transparent;
  border: none;
  color: #000;
  box-shadow: none;
}

.care_plan_list .accordion-item .accordion-header .accordion-button:focus {
  outline: none;
  box-shadow: none;
}

.btns_head .filter_icon {
  font-size: 21px;
  margin-left: 13px;
}

.care_plan_list .accordion-body {
  padding: 10px 0px;
}

.wellbeign_list .filter_icon {
  font-size: 22px;
  margin-left: 12px;
  line-height: 27px;
}

.wellbeign_list input,
.wellbeign_list input:focus {
  padding: 6px 15px;
  border: none !important;
  border-radius: 5px;
  outline: none;
}

.react-mapbox-ac-menu {
  width: auto;
  position: absolute;
  z-index: 9999;
  background-color: #fff;
  border: 1px solid #ccc;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-top: -1.3rem;
}

.react-mapbox-ac-input {
  margin-bottom: 1.5rem;
}

.react-mapbox-ac-suggestion {
  font-size: 18px;
  margin-bottom: 0.5rem;
  cursor: pointer;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.react-mapbox-ac-suggestion:hover {
  background-color: #58a;
}

.search {
  margin-bottom: 1.5rem;
}

.react-mapbox-ac-menu {
  width: 450px;
  position: absolute;
  z-index: 9999;
  background-color: #fff;
  border: 1px solid #ccc;
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-top: -1.3rem;
}

.react-mapbox-ac-input {
  margin-bottom: 1.5rem;
}

.react-mapbox-ac-suggestion {
  font-size: 18px;
  margin-bottom: 0.5rem;
  cursor: pointer;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.react-mapbox-ac-suggestion:hover {
  background-color: #58a;
}

.alignText {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.btns_head.mt-3.alignText span {
  color: #0c67bb;
  text-decoration: underline;
  cursor: pointer;
  margin-right: 40px;
}

.catList {
  gap: 10px;
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: end;
  align-items: center;
}

.catList li {
  background: #be0c0c;
  color: #fff;
  min-width: 60px;
  text-align: center;
  padding: 5px 8px;
  border-radius: 5px;
  margin-left: 10px;
  cursor: pointer;
  border: 1px solid #fff;
  line-height: 16px;
  font-size: 12px;
}

.catList li.high {
  border: 1px solid #be0c0c;
  background: #fff !important;
  color: #be0c0c;
}

.catList li.medium {
  border: 1px solid #d96a07;
  background: #fff !important;
  color: #d96a07;
}

.catList li.low {
  border: 1px solid #7d7d7d;
  background: #fff !important;
  color: #7d7d7d;
}

.catList h6 {
  font-size: 13px;
  color: #000;
  margin-bottom: 2px;
}

.living_box {
  border: 1px solid #bbb2b2;
  padding: 8px 15px;
  border-radius: 8px;
  position: relative;
  cursor: pointer;
  margin: 6px 0px !important;
  box-shadow: 0px 1px 4px 0px rgb(208 208 208 / 75%);
}

.living_box.active {
  border: 1px solid rgb(187, 107, 217);
}

.iconDownLoad {
  display: flex;
  align-items: center;
  gap: 20px;
  /* position: absolute;
  top: -28px;
  right: 160px; */
  /* transform: translate(-100%, -100%); */
}

.uploadIcon {
  transform: rotate(180deg) !important;
}

.col-md-12.left h5 {
  font-size: 18px;
}

.col-md-12.left p {
  font-size: 14px;
}

.iconDownLoad span {
  font-size: 28px;
  color: #bdbdbd;
}

.living_box p {
  margin: 0;
  font-size: 10px;
}

.care_plan_list .accordion-body {
  padding: 0px 0px 20px !important;
}

.living_box .fAction {
  position: absolute;
  right: 8px;
  bottom: 4px;
  background: #be0c0c;
  padding: 2px 4px;
  color: #fff;
  font-size: 12px;
  text-align: center;
  border-radius: 5px;
  max-width: 56px;
  min-width: 56px;
}

.living_box h5 {
  margin-bottom: 23px;
  font-size: 13px;
  font-weight: 400 !important;
}

.viewModal .left .catList {
  justify-content: left;
}

.viewModal .left .catList li {
  padding: 5px 5px;
  font-size: 14px;
}

.viewModal .left h6 {
  font-size: 16px;
  color: #000;
  margin: 15px 0px;
}

.qty_box ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.qty_box ul li .checkbox {
  font-size: 15px;
  font-weight: 500;
  color: #000;
  padding: 5px 10px 5px 28px;
  width: 100%;
  margin: 2px 0px;
}

.qty_box ul li .checkbox.active {
  background: #eac1f9;
}

.qty_box ul li .checkbox .checkmark {
  left: 5px;
  top: 7px;
}

.qty_box label {
  font-size: 17px;
  font-weight: 600;
  color: #000;
  margin-bottom: 10px;
  margin-top: 10px;
}

.viewModal .modal-header .modal-title {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}

.viewModal .modal-header .modal-title .btn {
  background: #9b51e0;
  color: #fff;
  font-weight: 500;
  font-size: 13px;
  padding: 5px 15px;
  margin-left: 8px;
}

.viewModal .modal-footer .btn {
  background: #9b51e0;
  color: #fff;
  font-weight: 500;
  font-size: 13px;
  padding: 5px 15px;
  margin-left: 8px;
}

.viewModal .modal-header .btn-close {
  display: none;
}

.viewModal .right p {
  margin-bottom: 25px;
}

.btn.theme_btn {
  background: #9b51e0;
  color: #fff;
  height: 35px;
  padding: 0px 15px;
  margin-left: 13px;
}

.removeBtn {
  border: 1px solid #c7c6c6;
  padding: 0px;
  width: 30px;
  height: 30px;
  font-size: 30px;
  line-height: 25px;
  position: absolute;
  right: 0px;
  top: 7px;
  display: flex;
  justify-content: center;
}

.viewModal .modal-body {
  padding: 0px 40px;
}

.details_list {
  list-style: none;
  margin: 0;
  padding: 0;
  color: #000;
}

/* .details_list li {
  display: flex;
  justify-content: space-between;
  line-height: 35px;
  color: #000;
  font-size: 14px;
} */
.details_list li {
  display: flex;
  line-height: 23px;
  color: #000;
  font-size: 14px;
  gap: 32px;
  margin-bottom: 17px;
}
.details_list li .leftSide {
  white-space: nowrap;
}
.cont_list {
  color: #000;
}

.cont_list strong {
  display: block;
  margin-bottom: 8px;
}

.profile_img img {
  width: 80px;
  height: 80px;
  border-radius: 50px;
  object-fit: cover;
}

.profile_img {
  text-align: right;
}

.invocing_box {
  border: 1px solid #9e61e7;
  padding: 15px 15px;
  width: 65%;
  border-radius: 5px;
}

.invocing_box h6 {
  color: #9e61e7;
}

.invocing_box_body {
  display: flex;
  border-radius: 5px;
  align-items: center;
}

.invocing_box_body .invocing_box_content ul {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-left: 30px;
}

.invocing_box_body .invocing_box_content ul li strong {
  font-weight: 500;
  color: #9e61e7;
  margin-right: 10px;
}

.invocing_box_body .invocing_box_content ul li span select {
  border: none;
  padding: 0px;
  color: #67757c;
}

.invoice_list {
  list-style: none;
  margin: 0;
  padding: 0;
  margin-top: 30px;
}

.invoice_list li {
  display: flex;
  justify-content: space-between;
  margin: 15px 0px;
}

.clientList .menuList {
  list-style: none;
  margin: 0;
  padding: 0;
}

.clientList .menuList li a {
  display: flex;
  text-decoration: none;
  color: #222;
  align-items: center;
  border: 1px solid #e7e7e7;
  padding: 10px 15px;
  border-radius: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}

.clientList .menuList li a .arrowIcon {
  position: absolute;
  right: 0;
  top: 50%;
  font-size: 35px;
  color: #a3a3a3;
  transform: translate(0%, -50%);
}

.starList {
  display: flex;
  justify-content: center;
}

.starList button {
  border: none;
  background: transparent;
  font-size: 24px;
  padding: 0px;
  color: #dadada;
}

.starList button.active {
  color: #ffca08;
}

.clientList .menuList li a .body p.timeBox {
  position: absolute;
  right: 0;
  top: 0;
}

.clientList .menuList .titlen {
  background: #fafafa;
  padding: 7px 10px;
  display: flex;
  justify-content: space-between;
  border: 1px solid #e5e5e5;
  font-size: 14px;
  margin-bottom: 5px;
}

.clientList .menuList li a img {
  width: 40px;
  height: 40px;
  border-radius: 50px;
  object-fit: cover;
}

.clientList .menuList li a .body {
  width: 100%;
  margin-left: 15px;
  position: relative;
}

.clientList .menuList li a .body h5 {
  font-size: 14px;
  margin-bottom: 0px;
}

.clientList .menuList li a .body p {
  margin-bottom: 5px;
  font-size: 12px;
}

.clientList .tabbingMenu .nav-pills .nav-item .nav-link {
  color: #4f4f4f;
  background: transparent;
  border-bottom: 3px solid transparent;
  border-radius: 0px;
  text-align: center;
  font-size: 18px;
}

.clientList .tabbingMenu .nav-pills .nav-item .nav-link.active {
  border-bottom: 3px solid #9b51e0;
}

.clientList .tabbingMenu .tab-content {
  margin-top: 20px;
}

.clientList .tabbingMenu .nav-pills .nav-item {
  width: 33.33%;
}

.card-body .top_menubar ul {
  gap: 0px !important;
}

.topButtonShow {
  margin-top: 0px;
  position: absolute;
  z-index: 999;
  right: 22px;
  top: 0;
}

.clientList .tabbingMenu .tab-content .TasksList {
  list-style: none;
  margin: 0;
  padding: 0;
}

.clientList .tabbingMenu .tab-content .TasksList li {
  margin-bottom: 20px;
  font-size: 16px;
}

ul.TasksList p {
  font-size: 13px;
}

.tab-content ul.TasksList li p {
  margin-bottom: 1px;
}

.colorIcon svg {
  color: #c1c1c1;
  font-size: 18px;
}

.clientList .tabbingMenu .tab-content .TasksList li .listRow {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 10px;
}

.clientList .tabbingMenu .tab-content .TasksList li input[type="checkbox"] {
  position: absolute;
  right: 0px;
  width: 20px;
  height: 20px;
}

.boxIcons {
  position: absolute;
  right: -3px;
  width: 20px;
  height: 20px;
}

.clientList .tabbingMenu .tab-content .TasksList li img {
  margin-right: 10px;
}

.taskCounts {
  margin: 0 0 0 auto;
  font-size: 13px;
  color: #67757c;
}

.alltaskCounts {
  margin: 0;
  font-size: 13px;
  color: #67757c;
}

.clientList .tabbingMenu .tab-content .TasksList .accordion .accordion-header .accordion-button {
  text-align: center;
  justify-content: center;
  font-size: 30px;
  padding: 0px;
  background: #fff;
  box-shadow: none;
  color: #222;
}

.clientList .tabbingMenu .tab-content .TasksList .accordion .accordion-item .accordion-collapse .accordion-body {
  padding: 10px 0px;
  margin-bottom: 20px;
}

.clientList .tabbingMenu .tab-content .TasksList .accordion .accordion-item {
  border: none;
}

.clientList .tabbingMenu .tab-content .TasksList .accordion .accordion-item .accordion-collapse .accordion-body ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.clientList .tabbingMenu .tab-content .TasksList .accordion .accordion-item .accordion-collapse .accordion-body ul li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.clientList .tabbingMenu .tab-content .TasksList .accordion .accordion-item .accordion-collapse .accordion-body ul li label {
  margin-right: 20px;
  min-width: 150px;
}

.clientList .tabbingMenu .tab-content .TasksList .accordion .accordion-item .accordion-collapse .accordion-body ul li button {
  border: none;
  background: transparent;
}

.client_img {
  width: 50px;
  height: 50px;
  border-radius: 50px;
  margin-left: 2px;
}

.wellbineColor {
  filter: invert(62%) sepia(29%) saturate(498%) hue-rotate(56deg) brightness(88%) contrast(89%);
}

.loadingBox {
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  width: 100%;
  margin-bottom: 16px;
}

.customModalWidth {
  max-width: 100vw !important;
  /* Adjust the value to increase or decrease the width */
}

.customModalWidth .modal-dialog {
  max-width: 850px !important;
  /* Adjust the value to increase or decrease the width */
  margin: 10 auto !important;
  /* sCenter the modal horizontally */
}

.edit_imgicon {
  position: absolute;
  left: 64px;
  top: 0px;
  cursor: pointer;
  font-size: 22px;
}

.edit_imgicon input {
  width: 20px;
  height: 30px;
  font-size: 0;
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.profile_img {
  width: 80px;
  height: 80px;
  border-radius: 50px;
  object-fit: contain;
  overflow: hidden;
  margin: 0px 0px 0px auto;
}

.fontSize {
  font-size: 15px;
  color: #4f4f4f;
}

.edit_icon {
  color: #9b51e0;
  background-color: #fff;
  border-radius: 12px;
}

.upload_icon {
  position: relative;
  cursor: pointer;
}

.upload_icon input {
  width: 20px;
  height: 30px;
  font-size: 0;
  z-index: 1;
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.journalListScroll {
  height: calc(100vh - 235px);
  height: -moz-calc(100vh - 235px);
  height: -webkit-calc(100vh - 235px);
  overflow-y: auto;
}

.teamNameInNotes {
  text-align: end;
  color: #BB6BD9;
}

@media (max-width: 991px) {
  .action_mt {
    margin-top: 5px;
  }
}