.login_section .login_header {
  background-image: linear-gradient(to right, var(--main), var(--main2));
  color: #fff;
  padding: 40px 0px;
}
.login_section .login_header .img_box img {
  width: 100%;
}
.login_section .login_header .login_title h1 {
  font-size: 44px;
  font-weight: 600;
  margin-bottom: 25px;
}
.login_section .login_header .login_title p {
  margin: 0;
  font-size: 18px;
  line-height: 33px;
}

.login_section .login_header .logo_box a {
  font-size: 32px;
  color: #fff;
  font-weight: bold;
  text-decoration: none;
  letter-spacing: 2px;
}
.login_section .form_box {
  margin: 0 auto;
  padding: 60px 20px;
}
.login_section .form_box .form-control {
  background: #fff;
  height: 48px;
  border: 1px solid var(--main);
  margin-bottom: 15px;
  font-size: 14px;
  width: 100%;
}
.login_section .form_box .form-control.btn {
  background-image: linear-gradient(to right, var(--main), var(--main2));
  color: #fff;
  margin-top: 25px;
}
.login_section .form_box .forgot {
  float: right;
  font-size: 14px;
  font-weight: 500;
}
.login_section .form_box .option_box {
  text-align: center;
  margin-top: 15px;
}
.login_section .form_box .option_box p {
  margin: 0;
}
.login_section {
  background: #fff;
}
.login_section .form_box h4 {
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 20px;
  font-weight: 600;
  color: var(--main);
  font-size: 28px;
}
