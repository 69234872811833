.cardBox h5 {
  font-size: 20px;
  border-bottom: 1px solid #9e9999;
  padding-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.cardBox {
  margin-bottom: 40px;
}

.cardBox ul {
  list-style: none;
  margin: 0;
  padding: 0;
  line-height: 28px;
}

.cardBox .editIcon {
  font-size: 16px;
}

.contractList {
  display: flex;
  align-items: center;
  margin-top: 20px;
}

.contractList .contentBody {
  padding-left: 15px;
}

.contractList .contentBody p {
  margin: 0;
}

.contractList img {
  width: 80px;
  height: 80px;
  object-fit: cover;
  border-radius: 50px;
}

.contractList .contentBody :global(.btn) {
  border: 1px solid #bb6bd9;
  padding: 3px 15px;
  color: #bb6bd9;
  display: flex;
  align-items: center;
  line-height: 21px;
  margin-bottom: 5px;
}

.cltDetails .contractList .contentBody {
  width: 100%;
}

.accordion :global(.MuiAccordion-root) {
  width: 100%;
}
.accordion :global(.MuiAccordion-root) :global(.MuiAccordionSummary-content) p {
  word-break: break-all;
  padding-right: 70px;
}

.accordion {
  position: relative;
}

.accordion .deletebtn {
  height: 50px;
  position: absolute;
  right: 0;
  top: 0;
  width: 70px;
  text-align: center;
  cursor: pointer;
  line-height: 50px;
}
