.page_wrapper {
  padding-top: 60px;
  margin-left: 130px;
  margin-bottom: 47px;
  position: relative;
  background: #fff;
  transition: 0.2s ease-in;
  display: block;
}

.mainBox {
  box-shadow: rgba(50, 50, 93, 0.25) 0px 2px 5px -1px,
    rgba(0, 0, 0, 0.3) 0px 1px 3px -1px;
  border-radius: 10px;
}

.mainBox .shiftAssign_heading {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 11px 32px;
  padding-top: 23px;
  border-bottom: 1px solid #e0e0e0;
}

.mainBox .shiftAssign_heading h2 {
  font-size: 21px;
  font-weight: 400;
  margin-bottom: 0;
}

.mainBox .shiftAssign_heading button {
  border: none;
  background-color: transparent;
  color: #828282;
}

.dayFilterBox {
  padding: 26px 38px;
}

.configur_staff_select {
  position: relative;
}

.configur_staff_select::before {
  position: absolute;
  content: "";
  width: 1px;
  height: 24px;
  background: #bdbdbd;
  right: 25%;
  top: 7px;
}

.dayFilterBox :global(.form-select) {
  border-radius: 10px;
  border: 1px solid #bdbdbd;
  color: #828282;
  font-weight: 300;
  background-image: url(../../../public/images/downArrowFill.svg);
  background-size: 11px;
  box-shadow: none;
}

.dayFilterBox :global(.form-control) {
  border-radius: 10px;
  border: 1px solid #bdbdbd;
  color: #828282;
  font-weight: 300;
}

.borderRed {
  border: 1px solid #f50a0a !important;
}

.dayFilterBox :global(input[type="date" i]::-webkit-calendar-picker-indicator) {
  background-image: url(../../../public/images/date-calendar.svg) !important;
}

.saveBtn button {
  background: #9b51e0;
  color: #fff;
  border: none;
  padding: 8px 14px;
  border-radius: 14px;
}

.personDetail_main .personDetail_box {
  display: flex;
  gap: 15px;
  align-items: center;
  padding: 6px 5px;
  color: #828282;
  cursor: pointer;
}

.personDetail_main .personDetail_box .personImg img {
  width: 35px;
  height: 35px;
  border-radius: 50%;
  margin-bottom: 4px;
}

.personDetail_main .personDetail_box .personDetails p {
  margin-bottom: 0px;
  font-size: 15px;
  line-height: normal;
}

.personDetail_main .personDetail_box .personDetails span {
  font-size: 13px;
}

.rightShiftZone_main .greaterBtn {
  text-align: center;
}

.rightShiftZone_main .greaterBtn button {
  border: none;
  background: transparent;
}

.rightShiftZone_main .rightZoneSelect :global(.form-select) {
  font-size: 12px;
  background-size: 8px;
  border-radius: 39px;
  padding: 3px 11px;
}

.rightShiftZone_main .rightZoneSelect {
  position: relative;
}

.rightShiftZone_main .rightZoneSelect::before {
  position: absolute;
  content: "";
  width: 1px;
  height: 19px;
  background: #bdbdbd;
  right: 18%;
  top: 5px;
}

.hoverStaff:hover {
  background-color: #dddddd;
}

.selectedStaff {
  color: #9b51e0 !important;
}

.resourceInEvent {
  color: #fff;
  min-height: 20px;
  text-align: center;
}

.resourceInEvent p {
  margin-bottom: 0 !important;
  position: relative;
}

.resourceInEvent span {
  position: absolute;
  right: 7px;
}

.eventDiv {
  padding: 8px;
}

.eventDiv span {
  background: #2D9CDB;
  padding: 5px 14px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  color: #fff;
  margin-top: 5px;
}

.rotaResource :global(.resource-view) {
  display: none;
}