.admin_dashboard_card .card-body p {
    margin: 0px 0px 2px 0px;
    font-size: 14px;
}

.admin_dashboard_card .card-body p strong {
    color: var(--main);
}

.admin_dashboard_card .card-body ul {
    list-style: none;
    margin: 0;
    padding: 0;
    margin-top: 5px;
    margin-block: 5px;
    min-height: 23px;
}

.admin_dashboard_card .card-body ul li img {
    width: 34px;
    border-radius: 50px;
    margin-right: 3px;
    height: 34px;
    object-fit: cover;
    position: relative;
    z-index: 1;
}

.admin_dashboard_card .card-body .link {
    font-size: 14px;
    float: right;
    color: var(--main);
}

.card.admin_dashboard_card {
    background-image: linear-gradient(to right, var(--main), var(--main2));
    padding-top: 2px;
    height: 219px;
}

.card.admin_dashboard_card .card-body {
    background: #fff;
    padding: 0.5rem 1.25rem;
    padding-bottom: 0px;
}

.paddingTopNew {
    padding-top: 58px !important;
}

.card {
    margin-bottom: 7px !important;
}

.card .card-title {
    font-size: 21px !important;
}

.card .card-title span{
    font-size: 13px !important;
}

.admin_dashboard_card .card-body ul.notification_ul {
    display: block;
    width: 100%;
}

.admin_dashboard_card .card-body ul.notification_ul li a {
    text-decoration: none;
    color: var(--main);
    padding: 3px 12px;
    display: block;
}

.admin_dashboard_card .card-body ul.notification_ul li:nth-child(even) a {
    text-decoration: none;
    color: var(--main);
    background: #fcf6ff;
    padding: 4px 12px;
    display: block;
}

.admin_dashboard_card .card-body ul.notification_ul li a span {
    font-size: 13px;
    margin-bottom: 2px;
    display: block;
}

.admin_dashboard_card .card-body ul.notification_ul li a div {
    font-size: 14px;
}

.admin_dashboard_card .card-body ul.notification_ul li a div img {
    width: 26px;
    height: 26px;
    margin-right: 8px;
}

.admin_dashboard_card .card-body p.task.time {
    background: #E0E0E0;
    padding: 2px 15px;
    color: var(--main);
}

.admin_dashboard_card .card-body p.task.time strong {
    color: var(--main);
}

.admin_dashboard_card .card-body p.task.time span {
    font-size: 13px;
}

ul.filter_box {
    list-style: none;
    margin: 0;
    padding: 0;
    float: left !important;
    margin-bottom: 15px;
}

ul.filter_box button {
    font-size: 14px;
    text-decoration: none;
    /* display: inline-block; */
    padding: 0px 4px;
    font-weight: 500;
    color: #939393;
}

ul.filter_box button.active {
    color: var(--main);
}

ul.filter_box a {
    font-size: 14px;
    text-decoration: none;
    display: inline-block;
    padding: 0px 4px;
    font-weight: 500;
    color: #939393;
}

ul.filter_box a.active {
    color: var(--main);
}
.dashboard_graph_box .donut {
    height: 186px;
    width: 160px;
    float: right;
}

.admin_dashboard_card .card-body .dashboard_graph_box ul {
    list-style: none;
    margin: 0;
    padding: 0;
    /* float: left; */
    display: block;
}

.admin_dashboard_card .card-body .dashboard_graph_box ul li {
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
}

.admin_dashboard_card .card-body .dashboard_graph_box ul li stong {
    font-size: 18px;
    font-weight: 600;
}

.admin_dashboard_card .card-body .dashboard_graph_box .dashboard_graph_box {
    display: flow-root;
    width: 100%;
    height: 100%;
}

.dashboard_graph_box {
    display: flex;
}

.dashboard_graph_box_in {
    height: 152px;
    width: 130px;
    /* float: right; */
    position: relative;
    top: -5px;
}

.dashboard_graph_box_in .count {
    position: absolute;
    top: 58%;
    left: 38%;
    transform: translate(-50%, -50%);
    text-align: center;
    font-size: 12px;
    font-weight: 600;
    margin-top: -9px;
    margin-left: 2px;
    color: var(--main);
}

.date_filters .filter_box {
    gap: 10px;
    display: flex;
}

.heightSetCustom {
    height: 30px;
}

.notification_scroll {
    overflow: hidden;
    height: calc(100vh - 470px);
    height: -moz-calc(100vh - 470px);
    height: -webkit-calc(100vh - 470px);
    overflow-x: hidden;
    overflow-y: auto;
}

.datepicker_input input {
    width: 175px;
    font-size: 13px;
}

/* .date_filters{
    width: 30%;
} */
.clint_list_scroll {
    display: flex;
    overflow-x: scroll;
    min-height: 70px;
}

.hidden-box {
    display: none;
    /* Initially hide the box */
    position: absolute;
    top: 0;
    left: 0;
    box-shadow: 1px 1px 15px rgb(0 0 0 / 10%);
    /* background-color: rgba(0, 0, 0, 0.8);  */
    background-color: #fff;
    z-index: 1000;
    min-width: 10rem;
    padding: 0.5rem 0.5rem;
    margin: 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    background-clip: padding-box;
    border-radius: 0.25rem;
}

.hidden-box p {
    color: #67757c;
    font-size: 14px;
    font-weight: 500;
    line-height: 24px;
}

.alert_btns {
    display: flex;
    /* justify-content: end; */
    margin-left: 24px;
}

.alert_btns .btn {
    background-color: #BB6BD9;
    color: #fff;
    font-size: 11px;
    margin-left: 10px;
    padding: 3px 10px;
}

.alert_status .btn {
    font-size: 11px;
    margin-left: 34px;
    padding: 3px 10px;
}