.predefineTemplate .catList {
  justify-content: left;
  /* align-items: end; */
  margin-bottom: 15px;
}
.predefineTemplate {
  padding: 30px 30px;
  padding-bottom: 30px;
}
.predefineTemplate h5 {
  font-size: 16px !important;
}
.predefineTemplate p{
  font-size: 14px;
}
.predefineTemplate .menuListTop {
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: end;
}
.predefineTemplate .menuListTop .btn {
  background: #9b51e0;
  color: #fff;
  margin-left: 5px;
}
.predefineTemplate .removeBtn {
  border-radius: 50px;
  font-size: 10px;
  width: 30px;
  height: 30px;
  top: 0px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: 2px solid #bb6bd9;
  background: #fff;
  color: #bb6bd9;
  font-weight: 500;
}
.predefineTemplate .nextBtn {
  background: #9b51e0;
  color: #fff;
  min-width: 100px;
}
.quationBox ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.quationBox ul li label {
  color: #000;
  font-size: 13px;
  margin-bottom: 5px;
  width: 100%;
  padding: 5px 15px 5px 30px;
  line-height: 23px;
}

.predefineTemplate .modal-body h6 {
  font-size: 14px;
}
.quationBox ul li label.active {
  background: #b06bd942;
}
.quationBox ul li label span.checkmark {
  top: 8px;
  left: 5px;
}
.quationBox {
  margin-top: 20px;
}
.quationBox h5 {
  font-size: 18px;
  color: #000000;
  font-weight: 300;
  margin-bottom: 20px;
}
