.page-wrapper {
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .all-text {
    padding: 0px 63px;
  }

  .top-text {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .top h6 {
    color: #9b51e0;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
  }

  .top p {
    color: #9b51e0;
    font-weight: 300;
    font-size: 14px;
    line-height: 16px;
  }

  .text span {
    font-style: normal;
    font-weight: 300;
    font-size: 11px;
    line-height: 13px;
  }

  .nov {
    display: flex;
    gap: 20px;
    padding: 0;
    align-items: center;
  }

  .nov img {
    width: 25px;
    height: 25px;
  }

  .nov li {
    font-weight: 300;
    font-size: 13px;
    line-height: 15px;
    list-style: none;
  }

  .content-mid {
    display: flex;
    gap: 78px;
    align-items: center;
    padding: 12px 10px;
    justify-content: space-between;
  }

  .content-mid p {
    color: #585858;
    font-weight: 300;
    font-size: 10px;
    line-height: 12px;
    text-align: end;
  }
}

/* 
table#RBS-Scheduler-root {
  width: 100% !important;
}
.scheduler-view {
  width: 100% !important;
} */

.control {
  border: none;
  background-color: #fafafa;
  outline: none;
  height: 60px;
  border-radius: 10px;
}

.control::placeholder {
  color: #585858;
  font-weight: 300;
  font-size: 13px;
  line-height: 15px;
  padding: 0px 70px;
}

.Employee-items tr td {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  font-weight: 300;
  font-size: 13px;
  line-height: 15px;
  color: #585858;
}

.Employee-items {
  display: flex;
}

.Employee-items span {
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  font-weight: 300 !important;
  font-size: 13px;
  line-height: 15px;
  color: #585858;
  padding: 4px 15px;
}

.add_sign {
  color: var(--main) !important;
  font-size: 18px !important;
  cursor: pointer;
  text-align: center;
}

/* table,
tbody,
tr,
th,
td {
  background-color: rgba(0, 0, 0, 0) !important;
} */

.input-box {
  position: relative;
}

.input-box img {
  position: absolute;
  top: 0px;
  margin-top: 5px;
  margin-left: 8px;
}

.input-box-download {
  position: absolute;
  top: 0px;
  right: 0px;
  margin-right: 100px;
  margin-top: 14px;
  font-size: 20px;
}

.input-box-button {
  border: none;
  background: #9b51e0;
  border-radius: 5px;
  padding: 2px 7px;
  height: 30px;
  width: 54px;
}

.input-box-button button {
  background: none;
  border: none;
  color: #fafafa;
}

.input-box-printer {
  position: absolute;
  right: 0px;
  top: 0px;
  margin-top: 14px;
  font-size: 20px;
  margin-right: 143px;
}

.selectedPayMonth {
  color: #9b51e0;
}

.paymonths_style {
  background-color: white;
  cursor: pointer;
  padding: 10px;
  transition: background-color 0.3s ease;
  /* Add a smooth transition effect */
}

/* Hover state styles */
.paymonths_style:hover {
  background-color: lightgray;
  /* Change background color to light gray on hover */
}

.selectedPayMonth p {
  color: #9b51e0 !important;
}

.overview_header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.scheduler {
  margin: 0px 0px !important;
  margin-top: -36px !important;
  border-spacing: 0;
  font-size: 13px;
}

.overview_img {
  width: 35px;
  height: 35px;
  object-fit: fill;
  /* border: 1px solid #e0e0e0; */
  border-radius: 35px;
}

.frequency_div {
  /* height: 500px;  */
  max-height: calc(100vh - 218px);
  max-height: -webkit-calc(100vh - 218px);
  overflow-y: scroll;
}


.payroll_back {
  position: absolute;
  left: 210px;
  top: 6px;
  color: #0d6efd;
  cursor: pointer;
  z-index: 1;
  font-size: 13px;
}