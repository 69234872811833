.headerStart {
    padding: 17px 0px;
    background-color: #fff;
}

.headerStart .headerTop {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.logoImg {
    width: auto;
    height: 24px;
    position: absolute;
    cursor: pointer;
    z-index: 1;
}

.headerStart .headerTop h5 {
    font-size: 16px;
    font-weight: 400 !important;
    font-family: Roboto !important;
    margin-bottom: 0px;
    color: #9B51E0;
}

.userDropdown :global(.dropdown) button {
    padding: 0px;
    background: white;
    border: none;
    color: #828282;
    font-size: 12px;
    font-weight: 300;
    font-family: Roboto !important;
    display: flex;
    align-items: center;
    gap: 13px;
}

.userDropdown :global(.dropdown) button:after {
    display: none;
}

.userDropdown {
    position: absolute;
    right: 16px;
    top: 6px;
}

.userDropdown :global(.dropdown) img {
    width: 40px;
    height: 40px;
    border-radius: 50px;
    object-fit: cover;
}

.userDropdown :global(.dropdown) :global(.dropdown-menu) {
    padding: 0px;
    border-radius: 5px;
    overflow: hidden;
    min-width: 243px;
    inset: 0px auto auto auto !important;
    transform: translate(-40%, 10%) !important;
    padding-bottom: 26px;
}

.userDropdown :global(.dropdown) :global(.dropdown-menu) li .userImages {
    background-color: #F2F2F2;
    padding: 18px 30px;
    text-align: center;
}

.userDropdown :global(.dropdown) :global(.dropdown-menu) li .userImages h3 {
    font-size: 15px !important;
    margin-bottom: 2px;
    font-weight: 300;
    font-family: Roboto !important;
    color: #F2994A;
}

.userDropdown :global(.dropdown) :global(.dropdown-menu) li .userImages p {
    font-size: 11px;
    font-weight: 300;
    color: #2D9CDB;
    font-family: Roboto !important;
    margin-bottom: 4;
}

.editProfile_imgicon {
    position: absolute;
    left: 98px;
    top: -8px;
    cursor: pointer;
    font-size: 18px;
}

.editProfile_imgicon input {
    width: 20px;
    height: 30px;
    font-size: 0;
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.userDropdown :global(.dropdown) :global(.dropdown-menu) li :global(.nav-link) {
    /* padding: 9px 16px 0px; */
    color: #828282;
    font-size: 13px;
    font-weight: 300;
    font-family: Roboto !important;
}


.footerStart {
    padding: 30px 0px;
    text-align: center;
}

.footerStart .footer p {
    font-size: 12px;
    font-weight: 300;
    font-family: Roboto !important;
    color: #828282;
    margin-bottom: 0px;
}


.portalHome {
    background-color: #D87AC6;
    padding: 126px 0px;
}

.portalHome .portalBox {
    text-align: center;
}

.portalHome .portalBox img {
    width: 260px;
    height: auto;
    margin-bottom: 60px;
}

.portalHome .portalBox h3 {
    font-size: 20px;
    font-weight: 400;
    color: #F2C94C;
    margin-bottom: 17px;
}

.portalHome .portalBox p {
    font-size: 16px;
    font-weight: 400;
    color: #fff;
    margin-bottom: 60px;
}

.portalHome .portalBox a {
    max-width: 145px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    font-size: 16px;
    font-weight: 400;
    color: #BB6BD9;
    background-color: #fff;
    margin: 0 auto;
    text-decoration: none;
}


.LoginStart {
    padding: 105px 0px;
}

/* .LoginStart .LoginBox{

} */
.LoginStart .LoginBox img {
    display: flex;
    margin: 0px auto 90px;
    width: 220px;
}

.LoginStart .LoginBox h3 {
    margin-bottom: 0px;
    font-size: 18px !important;
    font-weight: 600;
    color: #585858;
    font-family: "Open Sans" !important;
}

.LoginStart .LoginBox p {
    margin-bottom: 0px;
    font-size: 14px !important;
    font-weight: 400;
    color: #585858;
    font-family: "Open Sans" !important;
}

.LoginStart .LoginBox .continueButton a {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    font-size: 14px;
    color: #fff;
    font-weight: 400;
    background-color: #BB6BD9;
    border-radius: 5px;
    font-family: "Open Sans" !important;
    margin-bottom: 28px;
}

.LoginStart .LoginBox .formInput {
    margin-top: 45px;
    margin-bottom: 16px;
}

.LoginStart .LoginBox .formInput .inputEmail {
    margin-bottom: 16px;
}

.LoginStart .LoginBox .formInput .inputEmail label {
    font-size: 14px;
    font-weight: 700;
    margin-bottom: 9px;
    color: #585858;
    font-family: "Open Sans" !important;
}

.LoginStart .LoginBox .formInput .inputEmail :global(.form-control) {
    height: 40px;
    padding: 0px 25px;
    border: 1px solid #E0E0E0;
    color: #BB6BD9;
}

.LoginStart .LoginBox .formInput .inputEmail :global(.form-control)::placeholder {
    color: #BDBDBD;
}

.LoginStart .LoginBox .formInput :global(.form-check-label) {
    font-size: 14px;
    font-weight: 400;
    font-family: "Open Sans" !important;
    color: #585858;
}

.LoginStart .LoginBox .formInput :global(.form-check-label) a {
    display: inline-block;
    padding: 0px;
    color: #2F80ED;
}

.LoginStart .LoginBox .formInput :global(.form-check-input) {
    border: 1px solid #bb6bd9;
}

.LoginStart .LoginBox .formInput :global(.form-check-input):checked {
    background-color: #bb6bd9;
}


.PortalMainStart {
    background-color: #F2F2F2;
    padding: 20px 0px;
}

.PortalMainStart .profileProtal h3 {
    margin-bottom: 10px;
    font-size: 15px !important;
    font-weight: 400;
    color: #F2994A;
    font-family: Roboto !important;
}

.PortalMainStart .profileProtal .profileBox {
    background-color: #fff;
    padding: 12px;
    min-height: 550px;
    max-height: 550px;
    overflow: auto;
}

.PortalMainStart .profileProtal .profileBox1 {
    background-color: #fff;
    padding: 0px 12px 12px;
    min-height: 254px;
    max-height: 254px;
    overflow: auto;
    position: relative;
}

.table tr td {
    font-size: 13px;
    padding: 8px 10px;
    font-weight: 300;
    color: #828282;
    font-family: Roboto !important;
}

.PortalMainStart .profileProtal .profileBox1 thead {
    position: sticky;
    top: 0px;
    background: #fff;
    width: 100%;
    border-bottom: none;
}

.PortalMainStart .profileProtal .profileBox tr td {
    text-align: left;
    font-size: 13px;
    font-weight: 300;
    color: #828282;
    font-family: Roboto !important;
}

.PortalMainStart .profileProtal .profileBox .profile {
    display: flex;
    align-items: center;
    gap: 20px;
    margin-bottom: 24px;
}

.PortalMainStart .profileProtal .profileBox .profile img {
    width: 60px;
    height: 60px;
    border-radius: 50px;
}

.PortalMainStart .profileProtal .profileBox .profile h4 {
    font-size: 16px;
    font-weight: 300;
    font-family: Roboto !important;
    color: #9B51E0;
}

.PortalMainStart .profileProtal .profileBox ul {
    padding-left: 0px;
    margin-bottom: 0px;
    list-style: none;
    max-width: 90%;
}

.PortalMainStart .profileProtal .profileBox ul li {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 10px;
}

.PortalMainStart .profileProtal .profileBox ul li span {
    width: 50%;
    text-align: left;
    font-size: 13px;
    font-weight: 300;
    color: #828282;
    font-family: Roboto !important;
}

.PortalMainStart .profileProtal .profileBox ul h5 {
    font-size: 14px;
    margin: 10px 0px 19px;
    font-weight: 300;
    color: #9B51E0;
    font-family: Roboto !important;
}

.careJournalSection :global(.journalListScroll) {
    min-height: 548px;
    max-height: 548px;
    height: auto;
    overflow-y: auto;
}

.careJournalSection h5 {
    margin-bottom: 10px;
    font-size: 15px !important;
    font-weight: 400;
    color: #F2994A;
    font-family: Roboto !important;
}

.careJournalSection :global(.shiftsHistory) {
    margin-top: -32px !important;
    margin-bottom: 4px !important;
    justify-content: center;
}

.careJournalSection .careBox {
    background-color: #fff;
    min-height: 548px;
    max-height: max-content;
}

.careJournalSection .careBox .notAvailable {
    padding: 20px;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
}

.PortalMainStart .profileProtal .profileBox .carePlanBox {
    margin-bottom: 24px;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
}

.carePlanBtn {
    font-size: 14px;
    border: none;
    background: none;
    color: #9B51E0;
    text-decoration: underline;
}

.PortalMainStart .profileProtal .profileBox .timeDate {
    font-size: 12px;
    font-weight: 300;
    font-family: Roboto !important;
    margin-bottom: 20px;
    color: #828282;
}

.PortalMainStart .profileProtal .profileBox .messageChat {
    display: flex;
    align-items: center;
    gap: 35px;
    margin-top: 18px;
    padding: 10px 0px;
}

.PortalMainStart .profileProtal .profileBox .messageChat img {
    width: 30px;
    height: 30px;
    border-radius: 50px;
}

.PortalMainStart .profileProtal .profileBox .messageChat .messageBox {
    padding: 7px 25px;
    border-radius: 50px;
    position: relative;
}

.PortalMainStart .profileProtal .profileBox .messageChat .messageBox h5 {
    font-size: 11px;
    font-weight: 600 !important;
    margin-bottom: -3px;
    font-family: Roboto !important;
    color: #fff;
}

.PortalMainStart .profileProtal .profileBox .messageChat .messageBox span {
    color: #fff;
    font-size: 11px;
    font-weight: 400;
    font-family: Roboto !important;
    margin-bottom: 0px;
}
.PortalMainStart .profileProtal .profileBox .messageChat .messageBox time {
    position: absolute;
    left: 15px;
    bottom: -18px;
    width: 100%;
    font-size: 11px;
    color: #898787;
    text-align: left;
    font-weight: normal;
}

.chatMsgDates {
    display: inline-block;
    width: 100%;
    text-align: center;
    margin-top: 20px;
    border-top: 1px solid #d9d9d9;
}

.chatMsgDates span {
    position: relative;
    top: -16px;
    background: #fff;
    padding: 0px 10px;
    color: #918d8d;
    font-size: 12px;
}

.PortalMainStart .profileProtal .profileBox .scrollMessage {
    overflow: hidden;
    /* height: calc(100vh - 320px); */
    height: 473px;
    overflow-x: hidden;
    overflow-y: auto;
}


.profileProtal .profileBox .boxScroll {
    min-height: 225px;
    max-height: 225px;
    height: auto;
    overflow-y: auto;
}

:global(.react-datepicker__navigation) {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 45px;
}

.tasklist :global(.accordion-button::after) {
    display: none;
}

.tableHeader {
    position: sticky;
    top: 0;
    background-color: #f2f2f2;
    z-index: 1;
}

::-webkit-scrollbar {
    /* display: none; */
    width: 6px;
}

::-webkit-scrollbar-track {
    background-color: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background-color: #a8a7a7;
}

::-ms-scrollbar {
    width: 12px;
}

::-ms-scrollbar-track {
    background-color: #f1f1f1;
}

::-ms-scrollbar-thumb {
    background-color: #a8a7a7;
}



.PortalMainStart .profileProtal .profileBox .sendButton {
    margin-top: 20px;
    position: relative;
}

.PortalMainStart .profileProtal .profileBox .sendButton :global(.form-control) {
    border: 1px solid #BDBDBD;
    height: 30px;
    border-radius: 5px;
    width: 100%;
    color: #000;
    font-size: 11px;
    font-weight: 300;
}

.PortalMainStart .profileProtal .profileBox .sendButton :global(.form-control)::placeholder {
    color: #BDBDBD;
    opacity: 1;
}

.PortalMainStart .profileProtal .profileBox .sendButton button {
    position: absolute;
    right: 12px;
    top: 50%;
    transform: translate(0%, -50%);
    border: none;
    background-color: transparent;
    padding: 0px;
    color: #BDBDBD;
}

.clientNotesBox .rightShift {
    background-color: #fff;
    padding: 9px 15px !important;
}

.clientNotesBox .rightShift ul li a {
    border: none !important;
    border-radius: 0px !important;
    padding: 0px !important;
}

.clientNotesBox .rightShift ul li a :global(.body) h5 {
    color: #4F4F4F;
    font-weight: 300 !important;
}

.starList {
    display: flex;
    justify-content: center;
}

.starList .stars {
    border: none;
    background: transparent;
    font-size: 15px;
    padding: 0px;
    color: #dadada;
}

.starList .active {
    color: #ffca08 !important;
}

.rateBtn {
    background-color: #BB6BD9;
    color: #fff;
    border: none;
    margin-left: 10px;
    padding: 5px 15px;
    border-radius: 6px;
    line-height: 12px;
    font-size: 12px;
}

.clientNotesBox :global(.nav-pills) {
    justify-content: center;
    background: #fff;
    margin-top: 10px;
    padding: 15px;
}

.clientNotesBox :global(.nav-pills) :global(.nav-item) {
    width: auto;
}

.clientNotesBox :global(.nav-pills) :global(.nav-item) a {
    font-size: 14px !important;
    font-weight: 300 !important;
}

.clientNotesBox :global(.tab-content) {
    margin-top: 5px !important;
    background: #fff;
    padding: 12px;
    min-height: 372px;
}

.clientNotesBox :global(.tab-content) :global(.TasksList) :global(.listRow) img {
    width: 28px;
}

.checkImg {
    width: 15px !important;
}

.tasklist li {
    font-size: 15px !important;
}

.tasklist p {
    font-size: 12px !important;
}

.careBox li a {
    border: none !important;
    border-bottom: 1px solid #e7e7e7 !important;
    border-radius: 0px !important;
}

.careJournalSection :global(.datepicker_input) {
    position: absolute;
    right: 0px;
    transform: translate(100%, -90%);
}



.profilePage {
    padding: 30px;

}

.profilePage.displayHeight {
    min-height: calc(100vh - 160px);
}

.profilePage .ProfileBox {
    background-color: #fff;
    border-radius: 5px;
    padding: 20px;
}

.profilePage .ProfileBox .profileImg {
    margin-bottom: 45px;
}

.profilePage .ProfileBox .profileImg img {
    width: 80px;
    height: 80px;
    border-radius: 50px;
    display: flex;
    margin: 0 auto;
}

.profilePage .ProfileBox .topHeadeing {
    position: relative;
    text-align: center;
    margin-bottom: 28px;
}

.profilePage .ProfileBox .topHeadeing h3 {
    color: #828282;
    font-size: 18px !important;
    font-weight: 600;
    margin-bottom: 0px;
    font-family: "Open Sans" !important;
}

.profilePage .ProfileBox .topHeadeing .backIcon {
    position: absolute;
    top: 50%;
    transform: translate(0%, -50%);
    left: 0px;
}

.profilePage .ProfileBox .FromBox .box {
    padding-bottom: 25px;
    margin-bottom: 12px;
    border-bottom: 1px solid #E0E0E0;
}

.profilePage .ProfileBox .FromBox .box label {
    font-size: 16px;
    font-weight: 400;
    color: #9B51E0;
    font-family: "Open Sans" !important;
    margin-bottom: 4px;
}

.profilePage .ProfileBox .FromBox .box input {
    width: 100%;
    border: none;
    color: #828282;
    font-weight: 400;
    font-family: "Open Sans" !important;
    padding: 0px;
}

.RequestBox .heading h5 {
    margin-bottom: 14px;
    font-size: 18px;
    font-weight: 400;
    color: #828282;
    font-family: "Open Sans" !important;
    text-align: center;
}

.RequestBox .productBox {
    display: flex;
    align-items: center;
    padding-bottom: 18px;
    border-bottom: 1px solid #E0E0E0;
    margin-bottom: 24px;
    margin-top: 12px;
    gap: 10px;
}

.RequestBox .productBox .iconBox {
    text-align: center;
    width: 50%;
    position: relative;
    cursor: pointer;
    padding: 12px;

}

.SelectServicesActive {
    background: #0080000f;
    border-radius: 10px;
}

.RequestBox .productBox .iconBox span {
    display: block;
}

.RequestBox .productBox .iconBox img {
    width: 37px;
    height: 50px;
    margin-bottom: 12px;
}

.RequestBox .textAreaBox textarea {
    width: 100%;
    border: 1px solid #E0E0E0;
    padding: 12px 9px;
    color: #BDBDBD;
    font-weight: 400;
    font-size: 18px;
    font-family: "Open Sans" !important;
    height: 130px;
    border-radius: 5px;
}

.RequestBox .nextButton {
    margin-top: 18px;
}

.nextButton button {
    background-color: #BB6BD9;
    color: #fff;
    width: 100%;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    border: 1px solid #BB6BD9;
    font-size: 14px;
    font-weight: 400;
    font-family: "Open Sans" !important;
}

.FeedBackBox .feedbackText p {
    font-size: 17px;
    font-weight: 400;
    margin-bottom: 20px;
    font-family: "Open Sans" !important;
    color: #828282;
}

.FeedBackBox .feedbackText p a {
    display: contents;
    color: #2D9CDB;
    text-decoration: underline;
}


.CalendarBox .timeShow h5 {
    font-size: 19px;
    font-weight: 400;
    margin-bottom: 20px;
    font-family: "Open Sans" !important;
    color: #828282;
    margin-top: 20px;
}

.CalendarBox .timeShow .timeDropdown {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 18px;
}

.CalendarBox .timeShow .timeDropdown span {
    width: 100px;
    color: #828282;
    font-size: 16px;
    font-weight: 400;
    font-family: "Open Sans" !important;
}

.CalendarBox .timeShow .timeDropdown :global(.form-select) {
    height: 36px;
    border: 1px solid #BDBDBD;
    max-width: 140px;
    padding: 6px 18px;
}

.CalendarBox .timeShow .timeDropdown :global(.form-select):focus {
    outline: none;
    box-shadow: none;
}



.welcomeLogin .welcomeBox .topBanner {
    background-color: #D87AC6;
    height: 190px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.welcomeLogin .welcomeBox .LoginBox {
    background: #fff;
    padding: 30px 17px;
    border-radius: 20px;
    margin-top: -30px;
}

.welcomeLogin .welcomeBox .LoginBox h3 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 3px;
    color: #585858;
    font-family: "Open Sans" !important;
}

.welcomeLogin .welcomeBox .LoginBox p {
    color: #333333;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 0px;
}

.welcomeLogin .welcomeBox .LoginBox .formInput .inputEmail {
    margin-top: 40px;
}

.welcomeLogin .welcomeBox .LoginBox .formInput .inputEmail label {
    font-size: 14px;
    font-weight: 700;
    color: #585858;
    margin-bottom: 9px;
    font-family: "Open Sans" !important;
}

.welcomeLogin .welcomeBox .LoginBox .formInput .inputEmail :global(.form-control) {
    height: 40px;
    border: 1px solid #E0E0E0;
    border-radius: 5px;
    padding: 0px 26px;
    margin-bottom: 15px;
}

.welcomeLogin .welcomeBox .LoginBox .formInput :global(.form-check) {
    margin-bottom: 16px;
}

.welcomeLogin .welcomeBox .LoginBox .formInput :global(.form-check) :global(.form-check-input) {
    width: 20px;
    height: 20px;
    border-radius: 5px;
    border-color: #BB6BD9;
    margin-right: 10px;
}

.welcomeLogin .welcomeBox .LoginBox .formInput :global(.form-check) :global(.form-check-input):checked {
    background-color: #BB6BD9;
}

.welcomeLogin .welcomeBox .LoginBox .formInput :global(.form-check) :global(.form-check-label) {
    font-size: 14px;
    font-weight: 400;
    color: #585858;
    line-height: 22px;
    font-family: "Open Sans" !important;
    margin-top: -21px;
    margin-left: 10px;
}

.welcomeLogin .welcomeBox .LoginBox .formInput :global(.form-check) :global(.form-check-label) a {
    display: contents;
    color: #2F80ED;
}

.welcomeLogin .welcomeBox .LoginBox .termText {
    font-size: 14px;
    font-weight: 400;
    color: #585858;
    line-height: 22px;
    font-family: "Open Sans" !important;
    margin-top: 16px;
}

.teamName {
    text-align: end;
    color: #BB6BD9;
}

/* :global(.checkmark){
    
} */

@media (max-width: 767px) {
    .headerStart .headerTop h5 {
        position: absolute;
        bottom: -12px;
        font-size: 13px;
        left: 0px;
    }

    .careJournalSection :global(.shiftsHistory) {
        margin-top: 0px !important;
        margin-bottom: 13px !important;
        justify-content: start;
    }

    .careJournalSection :global(.datepicker_input) {
        position: relative;
        transform: translate(0%, 0%);
    }
}