.slider_section {
  /* background-image: linear-gradient(to right, var(--main), var(--main2)); */
  color: #fff;
  padding: 120px 0px 130px 0px;
}

.slider_section .login_title .btn {
  background: #BB6BD9;
  color: #fff;
}

.slider_section .login_title h1 {
  color: #BB6BD9;
  font-family: 'Source Serif Pro', serif;
}

.slider_section .login_title p {
  margin: 20px 0px 30px 0px;
  font-size: 20px;
  padding-right: 30px;
  color: #BB6BD9;
  font-weight: 300 !important;
}

.content_section {
  padding: 70px 0px;
}

.padding_botm {
  padding-bottom: 90px;
}

.content_section .content_body ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.content_section .content_body ul li {
  width: 50%;
  float: left;
  padding: 10px 0px;
}

.content_section .content_body ul li svg {
  color: var(--main3);
  font-size: 20px;
  position: relative;
  top: -1px;
  margin-right: 4px;
}

.content_section .content_body h3 {
  font-size: 24px;
  margin-bottom: 30px;
  color: var(--main3);
}

.content_section .content_body p {
  margin: 30px 0px 0px 0px;
  display: inline-block;
  font-weight: 300 !important;
}

.supporting_section .img_box img {
  width: 100% !important;
  margin-top: 0px !important;
}

/* .content_section .img_box .ellispeRightsideImg{
  position: absolute;
  margin-left: -195px;
  width: 262px;
  height: 256px;
  margin-top: -12px;
} */
.content_section .content_body {
  padding-left: 40px;
}

.supporting_section .img_box img {
  width: 100%;
}

.img_box_home img {
  width: 60% !important;
}

img_box_home_Friends&Family img {
  width: 50% !important;
}

.supporting_section .content_body {
  padding-left: 10px;
}

.supporting_section .content_body p {
  display: inline-block;
  margin: 0;
  margin-bottom: 24px;
  font-weight: 300 !important;
}

.supporting_section .content_body h3 {
  font-size: 24px;
  margin-bottom: 30px;
  color: var(--main3);

}

.supporting_section {
  background-color: #fafafa;
  padding: 70px 0px;
}

.supporting_section .btn {
  background: #fff;
  padding: 9px 30px;
  border: 1px solid var(--main);
  margin-top: 40px;
  color: var(--main);
}

.supporting_section .btn:hover {
  background: var(--main);
  border: 1px solid var(--main);
  color: #fff;
}

.delevring_box .delevring_box_body h4 {
  font-size: 22px;
  color: var(--main);
}

.delevring_box .delevring_box_body ul {
  margin: 0;
  padding: 0px 0px 0px 20px;
}

.delevring_box .delevring_box_body ul li {
  margin-top: 8px;
  font-size: 15px;
}

.delevring_box .delevring_box_body {
  padding-top: 35px;
}

.delevring_box .delevring_box_body h4 {
  margin-bottom: 20px;
  text-align: center;
}

.delevring_section {
  padding: 0px 0px 70px 0px;
}

.delevring_section .delevring_box_img img {
  height: 220px;
}

.delevring_section .delevring_box_img {
  text-align: center;
}

.delevring_box {
  padding: 0px 20px;
}

.delevring_section .title h3 {
  font-size: 24px;
  color: var(--main);
  margin: 30px 0px 0px 0px;
}

.delevring_section .title .bg_theme {
  background-image: linear-gradient(to right, var(--main), var(--main2));
  height: 30px;
  width: 100%;
}

.delevring_section .title {
  margin-bottom: 80px;
}

.slider_section .img_box img {
  width: 100%;
}

.trustedPartners {
  background-color: #F2F2F2;
  padding: 60px 0px 100px;
}

.trustedPartners .box_content h3 {
  margin-bottom: 120px;
  text-align: center;
  font-weight: 300 !important;
  color: var(--main3);
  font-size: 26px !important;
}

.trustedPartners .partner_box {
  margin-bottom: 60px;
}

.trustedPartners .partner_box .box_content {
  display: flex;
  align-items: center;
  gap: 13px;
  margin-bottom: 10px;
}

.trustedPartners .partner_box .box_content h5 {
  color: var(--main);
  font-size: 23px;
  font-weight: 300 !important;
  margin-bottom: 0px;
  font-family: 'Source Serif 4';
}

.trustedPartners .partner_box .box_content img {
  width: auto;
  height: 50px;
}

.trustedPartners .partner_box p {
  line-height: 25px;
  color: #67757c;
  position: relative !important;
  font-family: Roboto !important;
  font-weight: 300;
  font-size: 17px;
  margin-bottom: 10px;
}

.featured_mainSec {
  background: radial-gradient(82% 82% at 0% 0%, #F2994A 0%, #D5B81B 100%) ;
  padding: 150px 0px;
}

.featured_mainSec .content-box h2 {
  font-size: 75px;
  font-family: 'Source Serif 4';
  color: #fff;
}

.featured_mainSec .imgs-main ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

.featured_mainSec .imgs-main ul li {
  display: flex;
  gap: 60px;
  margin-bottom: 40px;
  justify-content: end;
}
.featured_mainSec .imgs-main ul li:last-child{
  margin-bottom: 0;
}
.featured_mainSec .imgs-main ul li .img-box {
  max-width: 235px;
}

.featured_mainSec .imgs-main ul li .img-box img {
  width: 100%;
}

@media (max-width: 991px) {
  .delevring_section .title {
    margin-bottom: 80px;
    margin-left: 0px;
  }

  .delevring_box {
    padding: 20px 20px;
  }

  .delevring_section .title {
    margin-bottom: 40px;
    margin-left: 0px;
    padding: 0px 15px !important;
    text-align: center;
  }

  .supporting_section .img_box img {
    margin-top: 30px;
  }

  .supporting_section .content_body {
    padding-left: 15px;
  }

  .content_section .content_body ul li {
    width: 100%;
  }

  .content_section .content_body {
    padding-left: 15px;
    margin-top: 50px;
  }

  .slider_section .img_box img {
    margin-top: 40px;
  }

  .facilities_row .content_box .bg_theme {
    margin-top: 10px;
  }

  .care_facilities_section .offset-3.title {
    margin: 0;
  }

  .facilities_row {
    margin-top: 40px;
  }

  .img_box_family {
    width: 60% !important;
  }
}


/* 
//////////////////////// 
*/

@media (max-width: 768px) {

  .resp_slider_section {
    padding: 10px 0px 80px 0px;
  }

  body .resp_slider_section_img img {
    padding: 20px !important;
  }

  body .resp_padding_botm {
    padding-bottom: 30px !important;
  }

  .resp_supporting_section {
    padding: 50px 0px
  }

  body .resp_img_box img {
    width: 100% !important;
    padding: 30px 20px 0px 40px !important;
  }

  .resp_care_facilities_section {
    padding: 25px 0px;
  }

  .resp_care_facilities_section_title {
    margin-left: 0px !important;

  }

  .resp_care_facilities_section_title {
    padding: 0px 30px !important;
    margin-bottom: -30px !important;
  }
}
@media (max-width: 768px) {
  .featured_mainSec .imgs-main ul li{
    gap: 16px;
    justify-content: center;
    margin-bottom: 10px;
  }
  .featured_mainSec .imgs-main ul li .img-box img{
    width: 100%;
    max-height: 57px;
  }
}
