@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@1,300&display=swap');
/* @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@1,300&family=Source+Serif+4:ital,wght@0,300;1,400&display=swap'); */
.care_facilities_section .title h3 {
  font-size: 24px;
  color: var(--main3);
  margin-bottom: 15px;
}
.care_facilities_section {
  padding: 60px 0px;
  /* background-color: #f2f2f2; */
}
.img_box_segment img{
  width: 60% !important;
}
.padding_botm {
  padding-bottom: 110px !important;
}

.youtubeVideo{
  padding: 60px 0px;
}

.facilities_row .content_box .bg_theme {
  background-image: linear-gradient(to right, var(--main), var(--main2));
  height: 2px;
  width: 100%;
}
.facilities_row .content_box h3 {
  font-size: 24px;
  color: var(--main);
  margin: 20px 0px 10px 0px;
  
}
.facilities_row .content_box ul {
  margin: 0;
  padding: 10px 0px 0px 20px;
}
.facilities_row .content_box ul li {
  margin-top: 8px;
}
/* .facilities_row {
  margin-top: 70px;
} */
.retirement_section {
  background: #fff !important;
  padding: 80px 0px;
}
.retirement_section .facilities_row {
  margin: 0;
}


/* ///////////////// */
@media (max-width: 768px){
  
  .resp_pagespace_section_title{
    margin-right: 50px;
  }
  body .resp_facilities_row{
    margin-top: -20px !important;
  }
  .resp_facilities_row_img img{
    width: 100% !important;
  }
  body .resp_care_facilities_section{
      padding-bottom: 25px !important;
  }
  .resp_care_facilities_section_padding{
    padding: 10px 0px !important;
  }
   .resp_facilities_row_img_footer img{
    width: 100% !important; 
    padding: 0px !important;
  }
}
/* 
////
*/
.segment_imgs img{
  width: 80% !important;
}