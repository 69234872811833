.removeBtn {
  line-height: 25px;
  border-radius: 50px;
  margin-left: 10px;
  font-size: 10px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: 2px solid #bb6bd9;
  background: #fff;
  color: #bb6bd9;
  font-weight: 500;
}

.dropdownRow {
  cursor: pointer;
  text-align: start;
  padding: 2px 5px;
  white-space: nowrap;
  text-overflow: auto;
}

.dropdownRow:hover {
  background: #bdd5fa;
}
.safeBox {
  overflow: hidden;
  overflow-x: auto;
  padding-bottom: 6px;

  &::-webkit-scrollbar {
    height: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.407);
    border-radius: 10px;
  }

  &::-webkit-scrollbar-track {
    background-color: #f0f0f0;
    border-radius: 10px;
  }
  scrollbar-color: rgba(0, 0, 0, 0.407) #f0f0f0;
  scrollbar-width: thin;
}
.reactAutocomplete :global(.MuiInputBase-root) {
  height: 33px !important;
  display: flex;
  align-items: center;
  padding: 0px 18px;
}

.reactAutocomplete :global(.MuiInputBase-root) input {
  height: auto;
  line-height: normal;
  padding: 0px !important;
  font-size: 14px;
}

.reactAutocomplete :global(.MuiAutocomplete-popper) {
  font-size: 14px !important;
}

.tasklist :global(.accordion-button::after) {
  display: none;
}

.accordionItems :global(.accordion-button::after) {
  margin-left: 70px !important;
}
