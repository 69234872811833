.blogmain_section {
  position: relative;
}
.blogmain_section .blogmain_body {
  position: absolute;
  bottom: 0px;
  /* left: 0; */
  width: 59% !important;
  color: #fff;
}
.blogmain_section .blogmain_body .btn {
  background-color: var(--main);
  color: #fff;
  min-width: 100px;
}
.blogmain_section .blogmain_body h3 {
  font-size: 20px !important;
  font-family: Roboto !important;
  width: 80%;
}
.main_blog{
  background: #00000094;
  opacity: 0.9;
  padding-top: 15px;
  width: 65%;
}
.blogmain_section .blogmain_body p {
  margin: 4px;
  font-size: 18px;
  width: 80%;
  /* background: #F2994A;
  opacity: 0.9; */
}
.blogmain_section .blogmain_body span {
  display: flex;
  justify-content: end;
}

.blogmain_section .blogmain_body .blog_read {
  color: #ffff !important;
  margin-left: 30px;
  margin-right: 35px;
  margin-bottom: 7px;
  font-size: 16px !important;
}

.blogmain_section img {
  width: 100%;
  height:400px;
  object-fit: cover;
}

.blogDetailsPage img {
  object-fit: cover !important;
}

.blog_box .blog_box_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.blog_box .blog_box_img {
  height: 220px;
  overflow: hidden;
  border-radius: 8px;
}
.blog_box .blog_box_body h2 {
  font-size: 25px;
  color: var(--main);
  margin: 0;
}
.blog_box .blog_box_body .btn {
  border: 1px solid var(--main);
  color: var(--main);
  min-width: 100px;
  padding: 5px 10px;
}
.blog_box .blog_box_body time {
  font-size: 14px;
}
.blog_box .blog_box_body p {
  margin: 10px 0px 20px 0px;
  font-size: 14px;
  font-family: 'Roboto' !important;
}
.blog_box_text{
  font-size: 16px !important;
}

.blog_box .blog_box_body {
  padding-top: 18px;
}
.blog_box {
  margin: 30px 0px;
}
.blog_section {
  padding: 40px 0px;
}

.blog_section .entRight {
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: end;
  margin-top: 50px;
}
.readmore {
  border: 1px solid var(--main);
  color: var(--main);
  min-width: 130px;
  margin-top: 40px;
}
/* .year_filter {
  text-align: right;
}
.year_filter span {
  text-align: right;
}
.year_filter select,
.year_filter select:focus {
  border: 1px solid var(--main);
  min-width: 100px;
  padding: 5px 10px;
  border-radius: 5px;
  color: var(--main);
  outline: none;
} */
.year_filter ul{
  list-style: none;
    display: flex;
    float: right;
    gap: 20px;
}

.year_filter ul li{
 font-size: 16px;
}
.year_filter ul li button{
  padding: 4px 25px;
  border:1px solid #9B51E0;
  border-radius: 5px;

}

.year_filter ul li a{
  color: #9B51E0;
  text-decoration: none;
}
.tabSelected{
  text-decoration: underline !important;
}

.year_filter ul li a:hover{
  color: #f2c94c;
}

.blog-para {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}
.blog-title {
  overflow: hidden;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

@media (max-width: 767px){
  .resp_blog_section{
  padding: 20px 0px !important;
}
.resp_blog_section .entRight{
  margin: 20px 0px !important;
}
.resp_blogmain_section .blogmain_body h3{
  font-size: 25px !important;
}
.resp_blogmain_section .blogmain_body p{
  opacity: .8 !important;
  margin-bottom: -35px !important;

}
.resp_blogmain_section .blogmain_body .btn {
  margin-bottom: -111px;
}
}

@media (max-width: 500px){
  .blogmain_section .blogmain_body {
    width: auto !important;
  }

}
@media (max-width: 375px){
  .blog_date {
    right: 30% !important;
  }
}

/* 
////
*/
.blog_date{
  position: absolute;
  font-size: 12px;
  font-weight: 300;
  bottom: 10px;
}
.blog-title{
  font-size: 16px !important;
}

.block-ellipsis{
  display: block;
  display: -webkit-box;
  line-height: 21px;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}