.cardBox ul {
    list-style: none;
    padding: 0;
    font-size: 15px;
    margin-top: 10px;
}

.cardBox ul li {
    display: flex;
    justify-content: space-between;
    font-weight: 400;
    font-size: 13px;
    line-height: 28px;
}

.cardBox ul li span {
    font-weight: 200;
    margin-left: 5px;
    font-style: normal;
    font-weight: 300;
    font-size: 13px;
}

.cardBox ul p {
    display: flex;
    justify-content: space-between;
    font-weight: 500;
    line-height: 28px;
    margin: 10px 0;
}

.cardBox ul p span {
    font-weight: 200;
    margin-left: 5px;
}

.licentre li{
    margin-left: 115px;
}

.editOn input{
    width: 68px !important;
    height: 20px;
}

.editOff input{
    width: 68px !important;
    height: 20px;
    border: none;
}

.wageEditBtn button{
    background: var(--main) !important;
    color: #ffff;
    border-radius: 10px;
    min-width: 55px;
    line-height: 16px;
    font-size: 15px;
}

.payrollInput{
    border: none !important;
    font-weight: 300;
    /* font-size: 13px; */
    line-height: 15px;
    color: #585858;
}

.detailDiv{
    margin-top: 10px;
    max-height: calc(100vh - 218px);
    max-height: -webkit-calc(100vh - 218px);
    overflow-y: auto;
}

@media (max-width: 767px) {
    .licentre li{
        margin-left: 15px;
    }
  }
  
@media only screen and (max-width: 991px) and (min-width: 768px) {
    .licentre li{
        margin-left: 35px;
    }
  }