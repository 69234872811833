.btns_head .btn {
  background-image: linear-gradient(to right, var(--main), var(--main2));
  color: #fff;
  border: none;
  margin-left: 10px;
  padding: 5px 15px;
}
.btn_table.btn-success {
  height: 26px;
  line-height: 1px;
  font-size: 12px;
}
.profile_img_table {
  width: 30px;
  height: 30px;
  object-fit: cover;
  border-radius: 50px;
}
.profle_img_box stong {
  margin-left: 5px;
}

.cts_datatable {
  font-family: "Poppins", sans-serif !important;
}
.table_arrow {
  font-size: 34px;
  position: absolute;
  right: 10px;
  color: #616161;
  top: -3px;
  padding: 0px 10px;
}
.ag-theme-alpine .ag-cell,
.ag-theme-alpine .ag-full-width-row .ag-cell-wrapper.ag-row-group {
  line-height: 53px !important;
}
.ag-theme-alpine [class^="ag-"],
.ag-theme-alpine [class^="ag-"]:focus,
.ag-theme-alpine [class^="ag-"]:after,
.ag-theme-alpine [class^="ag-"]:before {
  justify-content: center !important;
}
.ag-theme-alpine .ag-ltr .ag-cell {
  text-align: center !important;
}

.ract_tab_list li .nav-link {
  background: transparent;
  font-weight: 500;
  color: #6b6b6b;
  border: none;
  border-bottom: 2px solid #b3b3b3;
  padding: 5px 15px;
}
.ract_tab_list li .nav-link.active,
.ract_tab_list li .nav-link:hover,
.ract_tab_list li .nav-link:focus {
  background: transparent;
  border: none;
  color: var(--main);
  border-bottom: 2px solid var(--main);
}
.table_half tr td {
  width: 50%;
}
.table tr:nth-child(odd) td {
  background: #fafafa;
  box-shadow: none;
}
.table tr td {
  border: none;
  font-size: 14px;
  padding: 10px 15px;
}
.tb_title {
  font-size: 16px;
  margin-bottom: 15px;
  margin-top: 25px;
  color: var(--main);
}
.ract_tab_data {
  padding-top: 30px;
}
.seprate_line {
  border-right: 1px solid var(--main);
}
.table > :not(:first-child) {
  border-top: 1px solid #dddddd;
}
.table > thead tr th {
  font-size: 14px;
  font-weight: 500;
  border: none;
  padding: 10px 15px;
  color: var(--main);
}
.top_menubar .nav-tabs {
  float: left;
}
.top_menubar {
  display: inline-block;
  width: 100%;
}

.care_plan_list .accordion-item .accordion-header .accordion-button {
  padding: 15px 2px;
  border-radius: 0px;
}
.care_plan_list .accordion-item {
  border: none;
  border-radius: 0px;
  border-bottom: 1px solid #dedede;
}
.care_plan_list .accordion-item .accordion-header .accordion-button img {
  margin-right: 10px;
}
.care_plan_list
  .accordion-item
  .accordion-header
  .accordion-button:not(.collapsed) {
  background: transparent;
  border: none;
  color: #000;
  box-shadow: none;
}
.care_plan_list .accordion-item .accordion-header .accordion-button:focus {
  outline: none;
  box-shadow: none;
}

.btns_head .filter_icon {
  font-size: 21px;
  margin-left: 13px;
}
.care_plan_list .accordion-body {
  padding: 10px 0px;
}
.wellbeign_list .filter_icon {
  font-size: 22px;
  margin-left: 12px;
  line-height: 27px;
}
.wellbeign_list input,
.wellbeign_list input:focus {
  padding: 6px 15px;
  border: none !important;
  border-radius: 5px;
  outline: none;
}

.prospects_box_content ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.prospects_box_content ul li strong {
  font-weight: 500;
  color: #000;
  margin-right: 10px;
}
.prospects_box_content ul li span select {
  border: none;
  padding: 0px;
  color: #000;
}
.prospects_box_content {
  border: none;
  padding: 10px 15px 0px 15px;
  border-radius: 5px;
  height: 100%;
}

.border4 {
  border: 1px solid #9e61e7;
}

.border4 ul li {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.border4 ul li span {
  font-size: 14px;
  color: #4f4f4f;
  font-weight: 300;
}
.prospects_box_content h5 {
  color: #9e61e7;
}

.border4 .backbutton span {
  /* position: absolute;
  top: 0px;
  right: 30px; */
  color: #2d9cbd;
  font-size: 14px;
  font-weight: 300;
  margin-top: 5px;
  cursor: pointer;
  text-decoration: underline;
}

.edit_lead_head{
  display: flex;
  justify-content: space-between;
}

.dropdownAction {
  display: flex;
  align-items: center;
  gap: 60px;
}

.backSideButton {
  color: #2f80ed !important;
  position: absolute;
  top: 4px;
  left: 335px;
  transform: translate(-100%, 0%);
  z-index: 11;
  font-size: 12px;
}

.card-body .top_menubar ul {
  gap: 20px;
}

.page-wrapper .card-body .top_menubar ul.listspaceGap {
  gap: 20px !important;
}
.dropdownAction .iconFilter span svg {
  font-size: 24px;
  rotate: 90deg;
  color: var(--main);
  cursor: pointer;
}
.dropdownAction button {
  background-color: var(--main) !important;
  border: 1px solid var(--main);
  border-radius: 40px;
  min-width: 110px;
  padding: 5px 8px;
  color: #fff;
}

.activity_box h5 {
  color: #9e61e7;
  border-bottom: 1px solid #c7c7c7;
  padding: 0px 0px 10px 0px;
}
.activity_box {
  margin-top: 10px;
}
.activity_box ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.activity_main {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  font-size: 13px;
}
.activity_box ul li {
  margin-bottom: 10px;
}
.activity_main .call_history p {
  margin-bottom: 0px;
  /* color: #9e61e7; */
}
.activity_main .call_history span {
  font-size: 15px;
}

.history .body {
  margin-left: 10px;
}
.history .body h6 {
  margin-bottom: 2px;
  color: #e49231;
}
.history .body p {
  color: #626262;
}

.status_btn {
  font-size: 12px !important;
  width: 86px;
}

.activity_icons li {
  margin-left: 8px;
}

.activity_margin {
  margin-top: 45px;
}

.sales_date_filter {
  display: flex;
}

.topcontent {
  margin-block: -64px;
  margin-left: 54px;
}
.calendertop {
  padding-top: 13px !important;
}
.toptextsales {
  /* margin-bottom: 41px !important; */

  margin-block: -64px;
  position: absolute;
  right: 45px;
  top: 8px;
  transform: translate(0%, 0%);
}
/* .topleadsdate{
    margin-block: -63px !important;
} */
.datepicker {
  margin-left: 20px !important;
}
.datepicker .react-datepicker-wrapper .react-datepicker__input-container input {
  border: 1px solid #9e61e7 !important;
  font-size: 14px;
  width: 200px;
  padding: 2px 6px;
  border-radius: 3px;
}
.dateFilter_leads {
  position: absolute;
  top: -41px;
  right: 0px;
  width: 50%;
  display: flex;
  justify-content: end;
}

.dateFilter_sales {
  /* position: absolute; */
  /* top: -41px; */
  /* right: 0px;
    width: 50%; */
  /* margin-bottom: 8px; */
  display: flex;
  justify-content: end;
}



.table_sales tr td {
  border: none;
  font-size: 14px;
  padding: 3px 15px;
}