.support_box .donut {
  height: 180px;
  width: 150px;
}
.support_box .graph_box_in {
  height: 180px;
  width: 150px;
  float: unset;
  position: relative;
  margin: 0 auto;
  top: 15px;
}
.support_box .graph_box_in .count {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  font-size: 13px;
  font-weight: 600;
  margin-top: -12px;
  margin-left: 2px;
  color: var(--main);
}
