.footer-head{
    padding-bottom: 36px;
}


.footer-head h4{
        font-size: 36px;
        font-weight: 200;
        line-height: 42px;
        letter-spacing: 0em;
        text-align: left;
        padding-bottom: 15px;
        /* font-family: san serif!important; */
        font-family: 'Source Serif Pro', serif !important;
}



.footer-head .btn {
    display: inline-block;
    background: var(--main);
    color: #fff;
    min-width: 150px;
    margin-top: 20px;
    padding: 8px 0px;
}
.main_footer li{
    font-size: 16px !important;
    line-height: 18px !important;
}
/* .link_font_family li{
    font-family: 'Source Serif 4' !important;
} */

.footerPotential_img{
    text-align: end;
    margin-top: 11px;
}

/* .footerPotential_img img{
    width: 200px;
} */

/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/

@media (max-width: 767px) {
    .footerPotential_img{
        text-align: start;
    }
}