.addMeddropdown {
    /* background-color: rgba(255, 255, 255, 0.574); */
    display: flex;
    flex-direction: column;
    border: 1px solid gray;
    position: absolute;
    transform: translate(0px, -13px);
    z-index: 999;
    width: 100%;
    list-style: none;
    max-height: 155px;
    overflow-y: scroll;
    background: #fff;
    overflow-x: auto;
    /* margin-top: 192px; */
    /* margin-left: 70px; */
    border: 1px solid #ced4da;
  }

  .addMeddropdown:empty {
    border: none;
  }
  
  .dropdown:empty {
    border: none;
  }
  .dropdownRow {
    cursor: pointer;
    text-align: start;
    margin: 2px 5px;
    /* white-space: nowrap; */
    text-overflow: auto;
    background-color: #f8f6f5;
    padding: 1px 6px;
    /* overflow-x: auto; */
  }
  
  .dropdownRow:hover {
    background: #bdd5fa;
  }
  
  /* input {
    width: 220px;
  } */
  
  .searchInner {
    display: flex;
  }