:root {
  --main: #9e61e7;
  --main2: #d87ac6;
  --logo-bg: #9b51e0;
  --btn-bg: #9b51e0;
  --menu-active: #9b51e0;
  --pagination: #9b51e0;
  --checkbox_radio: #9b51e0;
  --tab_bg: #9b51e0;
  --yellow: #f2c94c;
}

body {
  overflow-x: hidden;
  margin: 0;
  color: #67757c;
  background: #eef5f9;
  position: relative;
  font-family: "Open Sans", sans-serif;
  font-weight: 400;
}
.left-sidebar {
  position: fixed;
  width: 240px;
  height: 100%;
  top: 0;
  z-index: 10;
  background-image: linear-gradient(to right, var(--main), var(--main2));
  box-shadow: 1px 0 20px rgb(0 0 0 / 8%);
  transition: 0.2s ease-in;
  padding-top: 70px;
}
.scroll-sidebar {
  position: relative;
  height: calc(100% - 50px);
}
.sidebar-nav ul {
  padding: 15px;
  background-image: linear-gradient(to right, var(--main), var(--main2));
  margin: 0;
}
.sidebar-nav ul .sidebar-item {
  width: 100%;
  margin-bottom: 10px;
  list-style: none;
}
.sidebar-nav ul .sidebar-item .sidebar-link {
  padding: 8px 15px 8px 8px;
  display: flex;
  border-radius: 4px;
  white-space: nowrap;
  align-items: center;
  position: relative;
  line-height: 25px;
  font-size: 15px;
  color: #fff;
  opacity: 1;
  text-decoration: none;
}
.sidebar-nav ul .sidebar-item .sidebar-link.active {
  color: var(--yellow);
}
.topbar .top-navbar {
  margin-left: 240px;
  padding-right: 15px;
}
.topbar {
  position: relative;
  height: 72px;
  background: #fff;
  z-index: 50;
  transition: 0.2s ease-in;
  /* box-shadow: 1px 0 7px rgb(0 0 0 / 5%); */
  position: fixed;
  width: 100%;
  top: 0;
}
.navbar-dark .navbar-brand {
  color: var(--main);
  font-size: 24px;
}

.dropdown.create_list h5 {
  font-size: 16px;
  font-weight: 300;
  color: var(--main);
  margin-bottom: 4px;
}
.dropdown.profile .nav-link::after {
  display: none;
}
.dropdown.create_list .waves-effect {
  padding: 0 !important;
  font-size: 14px;
  color: var(--main) !important;
}
.dropdown.create_list {
  text-align: right;
  margin-top: 8px;
}
.dropdown.create_list .dropdown-menu a {
  font-weight: 300;
  font-size: 14px;
  display: block;
  text-decoration: none;
  padding: 10px 15px;
  border-bottom: 1px solid #e5e5e5;
}
.dropdown.create_list .dropdown-menu {
  padding: 0;
  overflow: hidden;
}
.navbar-expand-md .navbar-nav .dropdown-menu a.dropdown-item.active {
  background-color: var(--main) !important;
}

.dropdown.create_list .dropdown-menu a:last-child {
  border: none;
}
.dropdown.create_list .dropdown-menu a.active {
  background: var(--main);
  color: #fff;
}
#main-wrapper .topbar .top-navbar .navbar-header {
  position: fixed;
  z-index: 10;
  width: 240px;
  left: 0;
  text-align: center;
  height: 72px;
  line-height: 72px;
  background-image: linear-gradient(to right, var(--main), var(--main2));
}
.topbar .dropdown .dropdown-toggle,
.topbar .dropdown .dropdown-toggle.active {
  display: flex;
  color: #000;
  align-items: center;
}
.topbar .dropdown .dropdown-toggle .body h5 {
  margin: 0px;
  padding: 0px 8px;
  font-size: 16px;
}
.navbar-dark .navbar-brand:hover,
.navbar-dark .navbar-brand:focus {
  color: var(--main);
}
.page-wrapper {
  padding-top: 70px;
  margin-left: 240px;
  position: relative;
  background: #eef5f9;
  transition: 0.2s ease-in;
  display: block;
}
.page-titles {
  padding: 15px 30px;
  background: #fff;
  box-shadow: 1px 0 5px rgb(0 0 0 / 10%);
  margin-bottom: 15px;
}
.page-titles .breadcrumb {
  padding: 0;
  margin: 0;
  background: 0 0;
  font-size: 14px;
}
.footer {
  background: #fff;
  border-top: 1px solid #e9ecef;
  padding: 17px 15px;
  margin-left: 240px;
}
.sidebar-nav ul .sidebar-item .sidebar-link svg {
  font-size: 18px;
  width: 30px;
  text-align: left;
}
.sidebar-nav ul .sidebar-item .sidebar-link .hide-menu {
  margin-left: 2px;
}
.sidebar-nav ul h5 {
  font-size: 16px;
  margin-top: 10px;
  margin-bottom: 15px;
  color: #fff;
  font-weight: 300;
}
.sidebar-nav ul hr {
  margin: 25px 0px 30px 0px;
}
.scroll-sidebar {
  position: relative;
  height: calc(100% - 59px);
  overflow-y: scroll;
}
.ps-container {
  -ms-touch-action: auto;
  touch-action: auto;
  overflow: hidden !important;
  -ms-overflow-style: none;
}
/* scroll start */
.scroll-sidebar::-webkit-scrollbar {
  width: 8px;
}
.scroll-sidebar::-webkit-scrollbar-track {
  background-color: transparent;
}
.scroll-sidebar::-webkit-scrollbar-thumb {
  background-color: rgb(158, 84, 223, 0.3);
}

/* scroll end */

.topbar .dropdown-menu {
  padding-top: 0;
  border: 0;
  box-shadow: 1px 1px 15px rgb(0 0 0 / 10%);
  position: absolute;
}
.navbar-expand-md .navbar-nav .dropdown-menu .bg-info {
  background: var(--main) !important;
}
.user-profile .dropdown-menu .btn-info,
.navbar-expand-md .navbar-nav .dropdown-menu .btn-info {
  background: var(--btn-bg) !important;
  border-color: var(--btn-bg) !important;
}
.navbar-expand-md .navbar-nav .dropdown-menu .bg-info h4 {
  font-size: 18px;
}
.navbar-expand-md .navbar-nav .dropdown-menu .bg-info p {
  font-size: 12px;
  font-weight: normal;
  margin-top: 4px;
}
.navbar-expand-md .navbar-nav .dropdown-menu a.dropdown-item {
  display: block;
}
.navbar-expand-md .navbar-nav .dropdown-menu .btn {
  color: #fff;
  padding: 10px 15px;
}
.min_height {
  display: block;
  min-height: 570px;
}
.card {
  margin-bottom: 30px;
}
.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 0 solid rgba(0, 0, 0, 0.125);
  border-radius: 4px;
}
.card-body {
  flex: 1 1 auto;
  padding: 1.25rem 1.25rem;
}
.card .card-title {
  margin-bottom: 15px;
  font-weight: 300;
  font-size: 24px;
  color: var(--main);
  display: inline-block;
  width: 100%;
}
.page-wrapper > .container-fluid {
  padding: 30px 30px;
}
.theme_color {
  color: var(--main);
}
a {
  color: var(--main);
}

.checkbox {
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  padding-left: 25px;
}
.checkbox:hover input ~ .checkmark {
  background-color: #f3f1f1;
  border: 1px solid #d8d8d8;
}
.checkbox input {
  opacity: 0;
  margin-right: 10px;
  position: absolute;
}
.checkbox input:checked ~ .checkmark {
  background-color: var(--main);
  border: 1px solid var(--main);
}
.checkbox input:checked ~ .checkmark:after {
  display: block;
}
.checkbox .checkmark {
  position: absolute;
  top: 3px;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #fff;
  border-radius: 3px;
  border: 1px solid #d8d8d8;
}
.checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 6px;
  top: 2px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 2px 2px 0;
  -webkit-transform: rotate(45deg);
  transform: rotate(45deg);
}

.radio {
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.radio:hover input ~ .checkmark {
  background-color: #f3f1f1;
  border: 1px solid #d8d8d8;
}
.radio input {
  opacity: 0;
  margin-right: 5px;
}
.radio input:checked ~ .checkmark {
  background-color: var(--main);
  border: 1px solid var(--main);
}
.radio input:checked ~ .checkmark:after {
  display: block;
}
.radio .checkmark {
  position: absolute;
  top: 5px;
  left: 0;
  height: 17px;
  width: 17px;
  background-color: #eee;
  border-radius: 50%;
  border: 1px solid #d8d8d8;
}
.radio .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 4px;
  top: 4px;
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: white;
}

.onof {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 25px;
}
.onof input {
  opacity: 0;
  width: 0;
  height: 0;
}
.onof input:checked + .slider {
  background-color: var(--main);
}
.onof input:focus + .slider {
  -webkit-box-shadow: 0 0 1px #2196f3;
  box-shadow: 0 0 1px #2196f3;
}
.onof input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  transform: translateX(26px);
}
.onof .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 25px;
}
.onof .slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 2px;
  bottom: 3px;
  background-color: #fff;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}
.hemburger_menu {
  border: none;
  background: none;
  font-size: 26px;
  display: none;
}
.left-sidebar .sidebar-footer {
  position: fixed;
  bottom: 0;
  width: 240px;
  display: flex;
  align-items: center;
  border-top: 1px solid #e9ecef;
  z-index: 99;
  transition: 0.2s ease-in;
  background-image: linear-gradient(to right, var(--main), var(--main2));
}
.left-sidebar .sidebar-footer a {
  padding: 15px;
  width: 33.333337%;
  text-align: center;
  font-size: 18px;
  color: #fff;
}
.table tr td {
  vertical-align: middle;
}
.modal .btn {
  font-size: 15px;
  font-weight: 300;
}
.modal .btn.btn-success {
  background-image: linear-gradient(to right, var(--main), var(--main2));
  border-color: var(--main);
}
.modal .form-control,
.modal .form-select {
  font-size: 14px;
}
.modal .form-label {
  font-size: 14px;
}

.dropdown.create_list .dropdown-menu select {
  width: 100%;
  border: none;
  font-weight: 300;
  font-size: 14px;
  display: block;
  text-decoration: none;
  padding: 10px 12px;
  color: var(--main);
}
.dropdown.create_list .dropdown-menu select:focus {
  box-shadow: none;
  outline: none;
}

.btns_head .btn {
  background: var(--main) !important;
  color: #fff;
  border: none;
  margin-left: 10px;
  padding: 5px 15px;
}
.top_menubar .nav-tabs {
  justify-content: center;
  float: unset !important;
}
body .ag-theme-alpine .ag-ltr .ag-cell {
  text-align: left !important;
}
body .ag-theme-alpine [class^="ag-"],
body .ag-theme-alpine [class^="ag-"]:focus,
body .ag-theme-alpine [class^="ag-"]:after,
body.ag-theme-alpine [class^="ag-"]:before {
  justify-content: left !important;
}
body .ag-theme-alpine [class^="ag-paging-panel"] {
  justify-content: center !important;
}
.top_menubar .back {
  font-size: 15px;
  position: absolute;
  font-weight: 500;
  top: 5px;
}

.profile_img {
  width: 100px;
  height: 100px;
  position: relative;
  object-fit: cover;
  background: #fff;
  padding: 3px;
  border: 1px solid #efeeee;
  border-radius: 5px;
}
.profile_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.profile_img input[type="file"] {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
  opacity: 0;
}
.submit_btn {
  background: var(--btn-bg);
  color: #fff !important;
  min-width: 180px;
  padding: 10px 10px;
  margin-top: 20px;
}

.margin_auto {
  margin: 0 auto;
}

/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/

@media (max-width: 767px) {
  .left-sidebar {
    left: -240px;
  }
  .page-wrapper {
    margin: 0;
  }
  .footer {
    margin: 0;
  }
  .topbar .dropdown .dropdown-toggle .body h5 {
    display: none;
  }
  .collapse:not(.show) {
    display: block;
  }
  .hemburger_menu {
    position: absolute;
    left: 20px;
    display: block;
    z-index: 11;
  }
  .topbar ul.navbar-nav {
    position: absolute;
    right: 20px;
    z-index: 11;
  }
  #main-wrapper .topbar .top-navbar .navbar-header {
    z-index: 10;
    width: 100%;
    left: 0;
    text-align: center;
    top: 15px;
  }
}
