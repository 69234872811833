.superAdminHeader ul h4{
    font-size: 18px;
    margin: 0px 0px 0px 3px;
    color: #9B51E0;
}
.superAdminHeader :global(.navbar-nav){
    align-items: center;
}
.superAdminHeader :global(.navbar-nav) :global(.dropdown) :global(.waves-effect){
    font-size: 16px;
    position: relative;
}