.login_section .login_header {
  background-image: linear-gradient(to right, var(--main), var(--main2));
  color: #fff;
  padding: 20px 0px;
}

.login_section .login_header .img_box img {
  width: 100%;
}

.login_section .login_header .login_title h1 {
  font-size: 44px;
  font-weight: 600;
  margin-bottom: 25px;
}

.login_section .login_header .login_title p {
  margin: 0;
  font-size: 18px;
  line-height: 33px;
}

.login_section .login_header .logo_box a {
  font-size: 32px;
  color: #fff;
  font-weight: bold;
  text-decoration: none;
  letter-spacing: 2px;
}

.login_section .form_box {
  margin: 0 auto;
  padding: 60px 0px;
}

.login_section .form_box .form-controll {
  height: 48px;
  border: 1px solid var(--main);
  margin-bottom: 15px;
  font-size: 14px;
}

.text_start {
  text-align: start;
}

.login_section .form_box .form-control.btn {
  background-image: linear-gradient(to right, var(--main), var(--main2));
  color: #fff;
  margin-top: 25px;
}

.login_section .form_box .forgot {
  float: right;
  font-size: 14px;
  font-weight: 500;
}

.login_section .form_box .option_box {
  text-align: center;
  margin-top: 15px;
}

.login_section .form_box .option_box p {
  margin: 0;
}

.login_section {
  background: #fff;
  font-size: 14px;
}

.login_section .form_box h4 {
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 20px;
  font-weight: 600;
  color: var(--main);
  font-size: 28px;
}

.slidecontainer {
  width: 100%;
  /* Width of the outside container */
}

/* The slider itself */
.slider {
  -webkit-appearance: none;
  /* Override default CSS styles */
  appearance: none;
  width: 75%;
  /* Full-width */
  height: 15px;
  /* Specified height */
  background: #d3d3d3;
  outline: none;
  /* Remove outline */
  opacity: 0.7;
  /* Set transparency (for mouse-over effects on hover) */
  -webkit-transition: 0.2s;
  /* 0.2 seconds transition on hover */
  transition: opacity 0.2s;
}

/* Mouse-over effects */
.slider:hover {
  opacity: 1;
  /* Fully shown on mouse-over */
}

/* The slider handle (use -webkit- (Chrome, Opera, Safari, Edge) and -moz- (Firefox) to override default look) */
.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  /* Override default look */
  appearance: none;
  width: 25px;
  /* Set a specific slider handle width */
  height: 25px;
  border-radius: 50%;
  /* Slider handle height */
  background: #9e61e7;
  /* Green background */
  cursor: pointer;
  /* Cursor on hover */
}

.slider::-moz-range-thumb {
  width: 25px;
  /* Set a specific slider handle width */
  height: 25px;
  border-radius: 50%;
  /* Slider handle height */
  background: #9e61e7;
  /* Green background */
  cursor: pointer;
  /* Cursor on hover */
}

.plan_select {
  display: flex;
  justify-content: center;
  align-items: center;
}

.plan_select .MuiFormControl-root {
  border: 1px solid var(--main);
  border-radius: 8px;
  right: -45%;
  color: var(--main) !important;
}

.currency_select {
  display: flex;
  justify-content: space-between;
}

.currency_select .MuiFormControl-root {
  border: 1px solid var(--main);
  border-radius: 8px;
  color: var(--main) !important;
}

.plan_select .css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-jedpe8-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
  color: var(--main);
}

.plan_select .css-hfutr2-MuiSvgIcon-root-MuiSelect-icon {
  fill: var(--main);
}

.coupen_btn {
  color: #fff;
  background-color: #9e61e7;
}

.countryDropdown {
  background-color: white;
  display: flex;
  flex-direction: column;
  border: 1px solid gray;
  position: absolute;
  width: 34%;
  list-style: none;
  max-height: 92px;
  overflow-y: scroll;
  border: 1px solid #ced4da;
}

.countryDropdown:empty {
  border: none;
}

.dropdownRow {
  cursor: pointer;
  text-align: start;
  margin: 2px 0;
  white-space: nowrap;
  text-overflow: clip;
  text-overflow: clip;
  overflow-x: clip;
  margin-left: 10px;
}

.dropdownRow:hover {
  background: #bdd5fa;
}

.loaderPage {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffffa8;
}

.loaderPage .spinnerLoader {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.input_error {
  color: red;
  margin-bottom: 5px;
  font-size: 12px;
  position: relative;
  top: 0px;
}

.privacy_policy {
  display: inline-block;
  color: "#8C00A4";
  font-size: 14px;
}

.select_Box_border {
  border: 1px solid var(--main);
  border-radius: 0.25rem;
}

.select_Box_border div {
  z-index: 9000 !important;
}

.error_bottom {
  margin-top: -12px;
}

.privacy_error {
  display: flex;
  justify-content: center;
}

@media (max-width: 767px) {
  .plan_select .css-1869usk-MuiFormControl-root {
    border: 1px solid var(--main);
    border-radius: 8px;
    right: -30%;
    color: var(--main) !important;
  }
}