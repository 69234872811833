/* body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  } */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.stripeContainer {
  width: 900px;
  margin-bottom: 60px;
}
.s-box {
  min-height: 433px;
  border: 1px solid #ddd;
  border-radius: 10px;
  padding: 12px 18px;
  background-color: #ffff;
  color: rgb(10, 8, 8);
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.15), 0 2px 2px rgba(0, 0, 0, 0.15),
    0 4px 4px rgba(0, 0, 0, 0.15), 0 8px 8px rgba(0, 0, 0, 0.15);
}
label {
  margin-bottom: 3px;
}
.spinner-border {
  width: 1.3rem;
  height: 1.3rem;
  border-width: 0.1em;
}

.success-msg {
  color: #0f5132;
  text-align: center;
  margin-top: 100px;
}
.success-msg svg {
  font-size: 60px;
  border: 1px solid #0f5132;
  border-radius: 30px;
  padding: 10px;
}
.success-msg .title {
  font-size: 25px;
  margin-top: 10px;
}
.card-btn {
  color: #ffff;
  background-color: #9e61e7;
  border-color: #9e61e7;
}
.infoPlaceholder::-webkit-input-placeholder {
  font-weight: lighter;
  color: #afb2b3;
}

.error-msg {
  color: #e76272;
  text-align: center;
  margin-top: 50px;
}
.error-msg svg {
  font-size: 60px;
  border: 1px solid #e76272;
  border-radius: 30px;
  padding: 10px;
}
.error-msg .title {
  font-size: 25px;
  margin-top: 10px;
}