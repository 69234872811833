.editProfile_imgicon {
    position: absolute;
    left: 145px;
    top: 5px;
    cursor: pointer;
    font-size: 22px;
}

.editProfile_imgicon input {
    width: 20px;
    height: 30px;
    font-size: 0;
    position: absolute;
    opacity: 0;
    cursor: pointer;
}