.text-default{
color: #9e61e7 !important;
} 

.care_facilities_section .title h3 {
  font-size: 24px;
  color: var(--main3);
  margin-bottom: 15px;
  text-align: center;
}
.care_facilities_section {
  padding: 60px 0px;

}
.sizeImg {
  margin-bottom: 60px;
}
.imgSize img {
  width: 80% !important;
}

.marginTop {
  margin-top: 100px !important;
}
.marginTopZero {
  margin-top: 0px !important;
}

.sizeImg img {
  width: 70% !important;
}
.bgColor {
  background-color: #f2f2f2 !important;
}
.care_facilities_section .rotaImage {
  margin-top: 46px;
}
.text-left {
  text-align: left !important;
}
.care_facilities_section .rotaImage img {
  width: 100%;
}
.facilities_row .img_box img {
  width: 80%;
}

.segment_end_img{
  width: 80% !important;
  margin-top: -70px !important;
}


.text-right {
  text-align: right;
}

.facilities_row .img_box_below img {
  width: 50%;
  display: flex;
  margin: auto;
  padding-top: 8%;
}

.facilities_row .content_box .bg_theme {
  background-image: linear-gradient(to right, var(--main), var(--main2));
  height: 2px;
  width: 100%;
}
.facilities_row .content_box h3 {
  font-size: 24px;
  color: var(--main);
  margin: 20px 0px 10px 0px;
}
.facilities_row .content_box ul {
  margin: 0;
  padding: 10px 0px 0px 20px;
}
.facilities_row .content_box ul li {
  margin-top: 8px;
}
.facilities_row .content_box ul li span {
  font-weight: bold;
}
.facilities_row {
  margin-top: 70px;
}
.retirement_section {
  background: #fafafa;
  padding: 80px 0px;
}
.retirement_section .facilities_row {
  margin: 0;
}

.care-plans-input {
  padding-top: 6%;
}

.care-plans-input h5 {
  /* padding-top: 30%; */
  font-size: 15px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0em;
  text-align: left;
}
.care-plans-input ul {
  margin: 0;
  padding: 0;
}
.care-plans-input ul li {
  list-style: none;
  display: flex;
  align-items: center;
  font-size: 14px;
  font-weight: 400;
  line-height: 25px;
  letter-spacing: 0em;
  text-align: left;
  padding-bottom: 4px;
}
.care-plans-input ul li input {
  padding: 0;
  height: 14px;
  width: 14px;
  border-radius: 1px;
  margin-right: 10px;
}

.static-img {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 60px 0px;
  width: 100% !important;
}


/*=======================================================
  //////////////RESPONSIVE MEDIA QUERY START //////////////
  =======================================================*/

  
/* ==
///// ROTA ////
*/
@media (max-width: 767px) {
  .static-img img {
    width: 100% !important;
  }
     /* 
     ///////////////// 
     */
  /* body .resp_pagespace_section{
    padding: 20px 0px 60px 0px !important;
  } */
 
  .resp_care_facilities_section .rotaImage{
    margin-top: 50px !important;
  }
.resp_static-img{
    padding: 10px !important;
  }
  .resp_retirement_section{
    padding: 0px;
  }
  .resp_facilities_row_img  img{
    width: 100% !important;
    /* padding: 30px 40px 30px 0px !important; */
  }



  /* 
///////////////         Featuredetailspages    ////////////   
//////////////////              ///////////////////////// */

  
  .respFM_facilities_row  img{
    width: 100% !important;
  }
  .respFM_retirement_section{
    padding: 30px 0px 0px 0px !important;
    padding-bottom: 30px !important;
  }
  .respFM_care_facilities_section{
    padding: 30px 0px !important;

  }
  .respFM_care_facilities_section .rotaImage{
    margin-top: 70px !important;
  }

  /* 
  ////// CarePlans
  */
  .resp_CaPl_facilities_row{
    margin-top: 35px !important;
  }
  .resp_CaPl_facilities_row .img_box img{
    width: 100% !important;
    padding: 10px 20px !important;
  }
  .resp_capl_care_facilities_section .rotaImage{
    margin-top: 10px;
  }
  .resp_Capl_retirement_section{
    padding: 20px 0px !important;
  }
  .resp_Capl_retirement_section .img_box img{
    width: 100% !important;
    padding: 10px 20px !important;
  }

  /* 
  ////// SalesFunnels
  */
 .resp_Em_static-img{
    padding: 0px !important;
  }



  /* 
  /////// PayrollCalculator
   */

   .resp_PaCa_care_facilities_section{
    padding: 40px 0px;
   }
   .resp_PaCa_care_facilities_section .img_box img{
    width: 100% !important;
    padding:  0px !important;
   }
   .resp_PaCa_care_facilities_section .facilities_row {
     margin-top: 40px !important;
   }
   .resp_PaCa_care_facilities_section  .rotaImage{
    margin-top: 40px;
    margin-bottom: 0px !important;
   }
   
  .resp_PaCa_care_facilities_section  .rotaImage img{
    width: 100% !important;
    padding: 20px 30px !important;

  }

  /* 
  //// Emar 
  */
.resp_Em_facilities_row{
    margin-top: 30px !important;
  }
  .resp_Em_facilities_row .img_box img{
    width: 100% !important;
  }
}
.care_oldImg{
  width: 80% !important;
}

