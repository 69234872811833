.map_section .map {
  width: 100%;
  height: 350px;
  border: 1px solid #dbdbdb;
}
.getStartButton {
  padding: 9px 16px;
  border: 1px solid #fff;
  text-decoration: none;
  color: #fff;
  border-radius: 5px;
  font-size: 16px;
  font-weight: 400;
  margin-top: 30px;
  display: inline-block;
}

.map_section {
  background: #fff;
  padding: 40px 0px;
}

.contact_section {
  background: #fafafa;
  padding: 50px 0px;
}

content_box .contact_section .contact_box {
  background: #fff;
  padding: 30px 30px;
  box-shadow: 0 2px 22px 6px #ebebeb;
}
.turbocharging_text p{
  font-size: 18px !important;
}

.contact_section .contact_box .title h3 {
  font-size: 24px;
  color: var(--main);
}

.sizeImagesFeatures img {
  padding: 0px !important;
  width: 80% !important;
}

.contact_section .contact_box .form-control {
  height: 45px;
  font-size: 15px;
  border-color: #e7e7e7;
  border-radius: 6px;
  width: 100%;
}

.contact_section .contact_box .form-control:focus {
  box-shadow: none;
  border-color: var(--main);
}

.contact_section .contact_box .form-group {
  margin-bottom: 22px;
}

.contact_section .contact_box hr {
  margin: 30px 0px;
}

.contact_section .contact_box .submit_btn {
  background: var(--main3);
  padding: 10px 40px;
  color: #fff;
}

.map_section .add_box h5 {
  color: var(--main3);
  margin-bottom: 18px;
}

.map_section .add_box p {
  margin-bottom: 0px;
  text-align: center;
}

.map_section .add_box {
  padding: 15px 15px;
}

.padding_botm {
  padding-bottom: 130px !important;
}

.data_secure_section {
 
  background: #9b9999;
  color: #fff;
}
.data_secure_section_items{
  /* background: #9b9999; */
  color: #fff;
  /* height: 300px; */
}
.data_secure_section_container{
  padding: 0px !important;
}
/* .data_secure_section_items .img_box img{
  width: 60% !important;
} */
/* .img_box_features{
  width: 100% !important;
} */
.img_box_features{
  text-align: end;
}
.img_box_feature img{
  width: 180px !important;
  height: auto !important;
}



.supporting_section.data_secure_section h3 {
  color: var(--hover);
}

.facilities_row .content_box .btn {
  display: inline-block;
  background: var(--main);
  color: #fff;
  min-width: 150px;
  margin-top: 20px;
  padding: 8px 0px;
}

.facilities_row .img_box img.empowering_img {
  width: 100%;
  padding: 0px 170px 0px 140px;
  position: absolute;
  left: 17px !important;
  top: 0px !important;
}

.supporting_section .btn.get_stared_btn {
  display: inline-block;
  background: transparent;
  color: #fff;
  border-color: #fff;
}

.supporting_section.get_stared_section {
  background: var(--main2);
  color: #fff;
}

.frame_section {
  background: #bdbdbd;
  color: #fff;
  padding: 80px 0px;
  font-family: 'SOURCE SERIF PRO';
}

.frame_section .title h3 {
  color: #9e61e7;
  padding-left: 100px;
  margin-bottom: 50px;
  font-weight: 500;
  font-size: 30px !important;
}

.frame_section .img_box img {
  width: 80%;
  color: #fff;
}

.frame_section .frame_row_1 .content_box p {
  margin: 0;
  position: absolute;
  left: 0;
  top: 80px;
  padding: 0px 50px;
}

.frame_section .content_box p b {
  font-size: 21px;
  font-weight: 600;
  color: #fff;
}

.supporting_section .content_body p b {
  font-size: 21px;
  font-weight: 600;
  color: #fff;
}

.supporting_section .img_box img {
  width: 70%;
  margin-top: -85px;
}

.frame_section .frame_row_1 .content_box {
  position: relative;
}

.frame_section .frame_row_2 .content_box p {
  margin: 0;
  position: absolute;
  left: 0;
  bottom: 80px;
  padding: 0px 50px;
}

.frame_section .frame_row_2 .content_box {
  position: relative;
}

.frame_section .frame_row_2 {
  margin-top: -200px;
}

.get_btn {
  display: inline-block;
  border: 2px solid #fff;
  color: #fff;
  background: var(--main);
  min-width: 140px;
  padding: 8px 10px;
  margin-top: 50px;
}

.facilities_row .img_box .featureImg {
  width: 60%;
  padding: 30px 50px;
}

/*=======================================================
//////////////RESPONSIVE MEDIA QUERY START///////////////
=======================================================*/
@media (max-width: 767px) {

  .resp_care_facilities_section_row{
    margin-top: 10px !important;
  }
  body .resp_care_facilities_section_row_img img{
    /* width: 80% !important; */
    /* padding: 30px 50px 0px 54px !important;   */
  }
  .resp_margin_top{
    margin-top: 10px !important;
  }
  .resp_facilities_row{
    margin-top: 0px !important;
  }
  .resp_care_facilities_section{
    padding-bottom: 30px !important;
  }
  .resp_frame_section{
    padding-bottom: 30px  ;
  }
  .resp_frame_section .title h3{
    margin-bottom: 10px !important;
  }
  .resp_frame_section .img_box img{
    padding: 15px 60px !important;
  }
  .resp_supporting_section_end img{
    width: 80% !important;
    
    padding: 0px 25px !important;

  }
  .img_box_features{
    text-align: center !important;
  }
}

@media (max-width: 991px) {
  .facilities_row .img_box img.empowering_img {
    padding: 0px 110px !important;
    position: relative;
    top: auto;
  }

  .supporting_section .img_box img {
    padding: 0px 50px;
    margin-top: 50px !important;
  }

  .facilities_row .img_box img {
    /* padding: 30px 40px !important; */
    width: 100%;
  }

  .frame_section .frame_row_2 .content_box p,
  .frame_section .frame_row_1 .content_box p {
    margin: 0;
    position: relative;
    left: 0;
    bottom: auto;
    padding: 0px 0px;
    top: 0;
    text-align: center;
    font-size: 18px;
  }

  .frame_section .frame_row_2 {
    margin-top: 50px;
  }

  .frame_section .img_box img {
    padding: 20px 60px;
  }

  .frame_section .title {
    margin: 0;
  }

  .frame_section .title h3 {
    padding-left: 0px;
    text-align: center;
  }

  .facilities_row .img_box .featureImg {
    padding: 30px 50px !important;
  }
 
}

@media only screen and (min-width: 768px) and (max-width: 991px) {}

@media only screen and (max-width: 5520px) and (min-width: 768px) {}

@media (min-width: 1200px) {}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .facilities_row .img_box img.empowering_img {
    width: 100%;
    padding: 0px 170px 0px 80px;
    position: absolute;
    left: 0px;
    top: -340px;
  }
}

/*=======================================================
  //////////////RESPONSIVE MEDIA QUERY END///////////////
  =======================================================*/